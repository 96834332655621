<template>
    <div class="personal-center">
        <div class="personal-center-contain" v-loading="loading">
            <!-- 头部基本信息展示 -->
            <div class="user-info">
                <div class="header-left">
                    <div class="user-info-left">
                        <div class="user-info-avatar" @click="handleUploadAvatar">
                            <img :src="avatarBase64 || $store.state.user.userInfo.avatarUrl + `?${Math.random()}`" v-if="$store.state.user.userInfo.avatarUrl" />
                            <img src="~@src/assets/images/default-user.png" v-else alt="">
                        </div>
                        <font-icon class="avatar-edit" href="#icon-bianji"></font-icon>
                    </div>
                    <div class="user-info-right">
                        <span class="user-info-realname">{{user.profile.realName}}</span>
                        <span class="user-info-nickname">@{{user.profile.nickname}}</span>
                        <div class="user-info-line">
                            <span class="user-info-title">{{user.profile.title}}</span>
                            <span class="halving-line">|</span>
                            <span class="user-info-firmname">{{user.profile.company}}</span>
                            <template v-if="user.profile.location">
                                <span class="halving-line">|</span>
                                <span class="user-info-location">
                                    <font-icon href="#icon-map-marker-o"></font-icon>
                                    <span>{{user.profile.location}}</span>
                                </span>
                            </template>
                        </div>
                        <div class="user-info-slogan">
                            <div class="user-info-slogan-text" v-if="!isEditSlogan">
                                <span class="slogan-text-content">{{user.profile.slogan ? user.profile.slogan : '这家伙很懒～什么也没留下'}}</span>
                                <!-- <i class="icon-edit" @click="() => {isEditSlogan = true;}"></i> -->
                                <span style="display: flex;align-items: center;" @click="() => {isEditSlogan = true;}">
                                    <font-icon class="slogan-edit" href="#icon-bianji"></font-icon>
                                </span>
                            </div>
                            <div class="user-info-slogan-form" v-else>
                                <textarea class="user-info-slogan-input" cols="20" rows="4" v-model="sloganInput" maxlength="50"></textarea>
                                <button class="user-info-slogan-btn" @click="handleEditSloganDone">保存</button>
                                <button class="user-info-slogan-btn user-info-slogan-btn-cancel" @click="() => {isEditSlogan = false; sloganInput = user.profile.slogan}">取消</button>
                            </div>
                        </div>
                        <div class="perference-tags">
                            <div class="perference-job-tabs" v-if="user.jobTags && user.jobTags.length > 0">
                                职位偏好：<span class="perference-tab-item" v-for="jobtag in user.jobTags" :key="jobtag">{{jobtag}}</span>
                            </div>
                            <div class="perference-candidate-tabs" v-if="user.candidateTags && user.candidateTags.length > 0">
                                人选偏好：<span class="perference-tab-item" v-for="candidatetag in user.candidateTags" :key="candidatetag">{{candidatetag}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="header-right">
                    <ul>
                        <li>
                            <div class="bg-circle">
                                <font-icon href="#icon-email"></font-icon>
                            </div>
                            <p :title="user.profile.email">{{user.profile.email}}</p>
                        </li>
                        <li>
                            <div class="bg-circle">
                                <font-icon href="#icon-telephone-out"></font-icon>
                            </div>
                            <p :title="user.profile.mobile">{{user.profile.mobile}}</p>
                        </li>
                    </ul>
                </div>
            </div>
            <!-- 标签选择页 -->
            <div class="tab-panel">
                <div class="tab-panel-head">
                    <span class="tab-panel-head-item" :class="tabIndex == 0 ? 'head-item-activity' : ''" @click="clickTab(0)">我的优势</span>
                    <span class="tab-panel-head-item" :class="tabIndex == 1 ? 'head-item-activity' : ''" @click="clickTab(1)">个人资料</span>
                    <span class="tab-panel-head-item" :class="tabIndex == 2 ? 'head-item-activity' : ''" @click="clickTab(2)">账号设置</span>
                </div>
                <div class="tab-panel-body">
                    <ul class="tab-panel-list">
                        <!-- 我的优势 -->
                        <li class="tab-panel-list-item" v-show="tabIndex == 0">
                            <div class="about-me">
                                <div class="about-me-item-null" v-if="!isHideItemNull && (!user.userProfileTags.introduction || user.userProfileTags.myIndustryTags.length == 0 || user.userProfileTags.myJobTags.length == 0 || user.userProfileTags.myCustomerTags.length == 0)">
                                    完善您的从业信息，更多的HR可以定向邀请您<i class="icon-close" @click="closeItemNull"></i>
                                </div>
                                <div class="about-me-item">
                                    <div class="about-me-item-header">我擅长的行业领域<span class="user-introduction-change" @click="clickEditBtn('industryAddTag')"><font-icon href="#icon-add"></font-icon>添加</span></div>
                                    <div class="about-me-item-body">
                                        <div class="user-industry-field-list" v-over2line v-if="user.userProfileTags.myIndustryTags.length > 0">
                                            <personalTag
                                                class="personal-tag-item"
                                                v-for="(indestryFieldItem, index) in user.userProfileTags.myIndustryTags"
                                                :key="indestryFieldItem.id"
                                                :tag="indestryFieldItem"
                                                :index="index"
                                                :type="0"
                                                @delete-tag="deleteTag"
                                            ></personalTag>
                                        </div>
                                        <div class="empty" v-else>请添加擅长的领域</div>
                                        <addPersonalTag ref="industryAddTag" class="personal-tag-add" :isNone="user.userProfileTags.myIndustryTags.length > 0 ? false : true" :industryField="industryField" :type="0" @add-tag="addTag"></addPersonalTag>
                                    </div>
                                </div>
                                <div class="about-me-item">
                                    <div class="about-me-item-header">我擅长的职位<span class="user-introduction-change" @click="clickEditBtn('skilledJobAddTag')"><font-icon href="#icon-add"></font-icon>添加</span></div>
                                    <div class="about-me-item-body">
                                        <div class="user-skilled-job-list" v-over2line v-if="user.userProfileTags.myJobTags.length > 0">
                                            <personalTag
                                                class="personal-tag-item"
                                                v-for="(skilledJobItem, index) in user.userProfileTags.myJobTags"
                                                :key="skilledJobItem.id"
                                                :tag="skilledJobItem"
                                                :type="1"
                                                :index="index"
                                                @delete-tag="deleteTag"
                                            ></personalTag>
                                            <!-- <div class="see-more">展开全部</div> -->
                                        </div>
                                        <div class="empty" v-else>请添加擅长的职位</div>
                                        <addPersonalTag ref="skilledJobAddTag" class="personal-tag-add" :isNone="user.userProfileTags.myJobTags.length > 0 ? false : true" :jobTypes="jobTypes" :type="1" @add-tag="addTag"></addPersonalTag>
                                    </div>
                                </div>
                                <div class="about-me-item">
                                    <div class="about-me-item-header">我有哪些公司资源<span class="user-introduction-change" @click="clickEditBtn('firmResourceAddTag')"><font-icon href="#icon-add"></font-icon>添加</span></div>
                                    <div class="about-me-item-body">
                                        <div class="user-firm-resource-list" v-over2line v-if="user.userProfileTags.myCustomerTags.length > 0">
                                            <personalTag
                                                class="personal-tag-item"
                                                v-for="(firmResourceItem, index) in user.userProfileTags.myCustomerTags"
                                                :key="firmResourceItem.id"
                                                :tag="firmResourceItem"
                                                :type="2"
                                                :index="index"
                                                @delete-tag="deleteTag"
                                            ></personalTag>
                                        </div>
                                        <div class="empty" v-else>请添加我的公司资源</div>
                                        <addPersonalTag ref="firmResourceAddTag" class="personal-tag-add" :isNone="user.userProfileTags.myCustomerTags.length > 0 ? false : true" :type="2" @add-tag="addTag"></addPersonalTag>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <!-- 个人资料 -->
                        <li class="tab-panel-list-item" v-show="tabIndex == 1">
                            <personal-info ref="personalInfoRef" :user="user" @change-profile="changeProfile"></personal-info>
                        </li>
                        <!-- 账号设置 -->
                        <li class="tab-panel-list-item" v-show="tabIndex == 2" v-loading="weChatBindLoading">
                            <h2 class="bind-title">账号信息</h2>
                            <div class="change-phone">
                                <el-form
                                    :model="ruleForm"
                                    :rules="rules"
                                    ref="ruleForm"
                                    class="account-ruleForm"
                                >
                                    <el-form-item prop="Mobile">
                                        <div class="my-label">手机号</div>
                                        <el-input
                                            class="account-input"
                                            type="text"
                                            v-model="ruleForm.Mobile"
                                            :disabled="!isEditMobile"
                                            placeholder="输入新手机号"
                                        ></el-input>
                                        <el-button
                                            v-show="!isEditMobile" 
                                            class="option-btn"
                                            @click="handleEditClick('mobile', 'edit')"
                                            :loading="submitLoading"
                                        >修改</el-button>
                                    </el-form-item>
                                    <!-- 滑块验证 -->
                                    <el-form-item v-show="isEditMobile">
                                        <div class="col-sm-12">
                                            <div id="ncContainer1" class="nc-container"></div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item prop="Code" class="code-form-item" v-show="isEditMobile">
                                        <div class="message-code">
                                            <el-input 
                                                v-model="ruleForm.Code"
                                                placeholder="输入验证码"
                                                class="get-code"
                                                >
                                                <el-button
                                                    slot="suffix"
                                                    class="get-code-btn"
                                                    :disabled="!canGetCode || !!timer" 
                                                    @click="getCode"
                                                    type="button"
                                                    :loading="codeLoading"
                                                    :class="{'green': ncValidPassed && canGetCode}"
                                                    >
                                                        <template v-if="!codeGetted">获取验证码</template>
                                                        <template v-else>{{timer ? `${countDown}秒后` : ''}}重新获取</template>
                                                </el-button>
                                            </el-input>
                                            <div class="help-not-receive">
                                                <p class="not-received-message-code" @click="helpVisible = true">没有收到短信验证码？</p>
                                                <el-card class="box-card" v-if="helpVisible">
                                                    <span class="closeHelp" @click="helpVisible = false">×</span>
                                                    <div class="text item">· 网络通讯异常可能会造成短信丢失，请重新发送短信</div>
                                                    <div class="text item">· 请核实手机是否欠费停机，或者屏蔽了系统短信</div>
                                                </el-card>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-show="isEditMobile">
                                        <el-button
                                            class="option-btn"
                                            @click="handleEditClick('mobile',  'cancel')"
                                            :loading="submitLoading"
                                        >取消</el-button>
                                        <el-button
                                            class="option-btn"
                                            type="primary"
                                            @click="handleChangePhone('ruleForm')"
                                            :loading="submitLoading"
                                        >保存</el-button>
                                    </el-form-item>

                                    <el-form-item prop="Email" class="email-form-item">
                                        <div class="my-label">邮箱</div>
                                        <div class="email">
                                            <el-input
                                                class="account-input"
                                                type="text"
                                                v-model="ruleForm.Email"
                                                placeholder="输入新邮箱"
                                                :disabled="!isEditEmail"
                                            ></el-input>
                                            <div v-if="!user.isEmployer && !user.profile.emailConfirmed">
                                                <span v-show="!isEditEmail" class="email-verify-text">未验证</span>
                                                <div class="help-not-receive" v-show="isEditEmail" >
                                                    <p class="not-received-message-code" @click="helpEmailVisible = true">收不到邮箱？</p>
                                                    <el-card class="box-card" v-if="helpEmailVisible">
                                                        <span class="closeHelp" @click="helpEmailVisible = false">×</span>
                                                        <div class="text item">· 检查下邮箱是否填写准确</div>
                                                        <div class="text item">· 查看是否发送至垃圾邮件中</div>
                                                    </el-card>
                                                </div>
                                                <el-button
                                                    v-show="!isEditEmail" 
                                                    class="option-btn"
                                                    @click="handleEditClick('email', 'edit')"
                                                    :loading="submitLoading"
                                                >去验证</el-button>
                                            </div>
                                            <div v-if="!user.isEmployer && user.profile.emailConfirmed">
                                                <span 
                                                    v-show="!isEditEmail" 
                                                    class="email-verify-text verified">
                                                    <font-icon class="icon-verify" href="#icon-yanzhengma"></font-icon>
                                                    已验证
                                                </span>
                                                <div class="help-not-receive" v-show="isEditEmail" >
                                                    <p class="not-received-message-code" @click="helpEmailVisible = true">收不到邮箱？</p>
                                                    <el-card class="box-card" v-if="helpEmailVisible">
                                                        <span class="closeHelp" @click="helpEmailVisible = false">×</span>
                                                        <div class="text item">· 检查下邮箱是否填写准确</div>
                                                        <div class="text item">· 查看是否发送至垃圾邮件中</div>
                                                    </el-card>
                                                </div>
                                                <el-button
                                                    v-show="!isEditEmail" 
                                                    class="option-btn"
                                                    @click="handleEditClick('email', 'edit')"
                                                    :loading="submitLoading"
                                                >更换</el-button>
                                            </div>
                                        </div>
                                    </el-form-item>
                                    <el-form-item v-show="isEditEmail">
                                        <el-button
                                            class="option-btn"
                                            @click="handleEditClick('email', 'cancel')"
                                            :loading="sendLoading"
                                        >取消</el-button>
                                        <el-button
                                            class="option-btn"
                                            type="primary"
                                            v-if="!isSendVerifyEmail" 
                                            @click="handleEmailVerify"
                                            :loading="sendLoading"
                                        >发送验证邮件</el-button>
                                        <button class="email-send-btn" v-else>{{remainSecondSend}}s 后可重新发送</button>
                                    </el-form-item>
                                </el-form>
                            </div>

                            <h2 class="bind-title">企业微信授权</h2>
                            <div class="bind-option" v-if="isAuthorize && !isBind">
                                <font-icon class="bind-icon" href="#icon-qiyeweixin"></font-icon>
                                <el-button @click="bindEnterpriseWeChat">绑定</el-button>
                            </div>
                            <div class="bind-option" v-else>
                                <font-icon class="bind-icon"  href="#icon-bianzu"></font-icon>
                                <!-- <el-button @click="unbindEnterpriseWeChat">解除绑定</el-button> -->
                                <span v-if="!isAuthorize">猎必得未授权自建应用代开发或应用未上线</span>
                                <span v-else>已绑定</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <upload-avatar
            ref="uploadAvatar"
        />

        <!-- 手机号验证弹窗 -->
        <MobileVerifyDialog ref="MobileVerifyDialogRef" @bind-success="bindSuccessRefresh"></MobileVerifyDialog>
        <!-- 错误提示弹窗 -->
        <ScanErrorDialog ref="scanErrorDialogRef"></ScanErrorDialog>
    </div>
</template>

<script>
    import UploadAvatar from '#/component/common/upload-avatar.vue';
    import personalTag from '#/views/personal-center/component/personalTag.vue';
    import addPersonalTag from '#/views/personal-center/component/addPersonalTag.vue';
    import ScanErrorDialog from './component/scan-error-dialog.vue';
    import MobileVerifyDialog from './component/mobile-verification-dialog.vue';
    import PersonalInfo from './component/personal-info.vue';

    import { validateMobile } from '#/js/util/validate.js';

    import { personal_center as personalCenterUrl } from '#/js/config/api.json';
    import CertificationService from '#/js/service/certificationService.js';

    export default {
        name: "personalCenter",
        components: {
            personalTag,
            addPersonalTag,
            UploadAvatar,
            PersonalInfo,
            ScanErrorDialog,
            MobileVerifyDialog,
        },
        data(){
            return {
                tabIndex: 1,

                isAuthorize: false,
                isBind: false,
                weChatBindLoading: false,
                
                loading: false,
                sendLoading: false, // 发送邮件验证的loading样式
                helpVisible: false, // 没有收到验证码的帮助信息弹窗
                helpEmailVisible: false, // 没有收到邮件的帮助信息弹窗
                submitLoading: false, // 提交更换手机号按钮的loading样式
                codeLoading: false, // 获取验证码按钮的loading样式
                codeGetted: false, // 获取到验证码时
                canGetCode: false, // 是否可以获取验证码
                countDown: 60*5, // 验证码倒计时
                timer: null,
                ncValidPassed: false, // 是否通过滑块验证
                token: '',
                sessionid: '',
                sig: '',
                authScene: 'nc_message',
                ruleForm: {
                    Mobile: "",
                    Code: "",

                    Email: "",
                },
                rules: {
                    Mobile: [
                        { required: true, message: '请输入新手机号', trigger: ['blur', 'change'] },
                        { validator: validateMobile, trigger: ['blur', 'change'] }
                    ],
                    Code: [
                        { required: true, message: '请输入短信验证码', trigger: ['blur', 'change'] },
                        { pattern: /^[0-9]{4}$/, message: '短信验证码为4位数字', trigger: ['blur', 'change'] }
                    ],
                },
                user: {
                    profile: {
                        avatarUrl: "",
                        realName: "",
                        nickname: "",
                        province: 0,
                        city: 0,
                        location: "",
                        username: "",
                        firmId: "",
                        company: "",
                        title: "",
                        email: "",
                        emailConfirmed: false,
                        mobile: "",
                        step: 0,
                        slogan: "",
                        introduction: "",
                        myFamiliarJobCategoryIds: [] 
                    },
                    userProfileTags: {
                        introduction: "",
                        myIndustryTags: [],
                        myJobTags: [],
                        myCustomerTags: []
                    },
                    jobTags: [],
                    candidateTags: [],
                    accountBalance: "",
                    isBindingPinganAccount: false,
                    isCFUser: false,
                    isEmployer: false,
                    pinganAccount: ""
                },
                isEditSlogan: false,
                industryField: [],
                jobTypes: [],
                verificationToken: "",
                sloganInput: "",
                isHideItemNull: false,
                avatarBase64: '',

                isEditMobile: false,
                isEditEmail: false,
                isSendVerifyEmail: false,
                remainSecondSend: 60,
                emailVerifyDone: false,
            }
        },
        watch: {
            // 判断是否填写了电话号码，电话号码填写正确了才可以点击获取验证码
            'ruleForm.Mobile': function(val) {
                if(/^(1[3-9])\d{9}$/.test(val)) {
                    this.canGetCode = true;
                } else {
                    this.canGetCode = false;
                }
            },
            isAuthorize: {
                handler(val) {
                    if(val && !this.isBind && this.$route.query.state && this.$route.query.state != 'undefined') {
                        // 这里接口判断下扫码的是不是当前授权的企业微信公司，如果不是报一个错误提示，是的话就继续下一步绑定操作（校验手机号）
                        this.getValidateUserBoundInfo();
                    }
                },
                immediate: true
            }
        },
        created() {
            this.getUserInfo();
        },
        mounted() {
            this.getFirmThirdBoundInfo();
            this.getUserThirdBoundInfo();

            if(this.$route.query.Tab) {
                switch(this.$route.query.Tab) {
                    case 'myAdvantage':
                        this.tabIndex = 0;
                        break;
                    case 'personalInfo':
                        this.tabIndex = 1;
                        break;
                    case 'accountSet':
                        this.tabIndex = 2;
                        console.log(this.$route.query);

                        // 点击邮箱验证链接过来，判断邮箱是否验证成功
                        if(this.$route.query.code) {
                            this.dealVerifyDone();
                        }
                        break;
                }
            } else {
                if(sessionStorage.getItem(`personalCenterTab`)) {
                    this.tabIndex = Number(sessionStorage.getItem(`personalCenterTab`));
                } else {
                    this.tabIndex = 0;
                }
            }

            if(this.$route.query.isEmailVerify && this.$route.query.isEmailVerify == 'true') {
                // 点击邮箱验证链接过来，判断邮箱是否验证成功
                if(this.$route.query.code) {
                    this.dealVerifyDone();
                }
            }
            
            this.verificationToken = this.$store.state.verificationToken.verificationToken;

            // this.initNc();
            if(this.tabIndex == 2) {
                this.initNc();
            }
            this.getIndustryFieldList();
            this.getJobTypeList();
        },
        methods: {
            // 个人资料修改成功
            changeProfile(data) {
                this.user.profile.nickname = data.nickName;
                this.user.profile.realName = data.realName;
                this.user.profile.introduction = data.introduction;
                this.user.profile.title = data.title;
                this.user.profile.location = data.location;
                this.user.profile.province = data.provinceSelect;
                this.user.profile.city = data.citySelect;

                this.getUserInfo();
            },

            bindSuccessRefresh() {
                this.getFirmThirdBoundInfo();
                this.getUserThirdBoundInfo();
            },
            getValidateUserBoundInfo() {
                let shortToken = decodeURIComponent(this.$route.query.state);
                CertificationService
                    .ValidateUserBoundInfo({
                        shortToken: shortToken
                    })
                    .then(res => {
                        console.log(res);
                        if(res == 9) {
                            this.$refs.scanErrorDialogRef.show();
                        } else {
                            this.$refs.MobileVerifyDialogRef.show(this.$store.state.user.userInfo.mobile, 'bind', shortToken);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    }).finally(() => {
                        this.weChatBindLoading = false;
                    });
            },

            clickEditBtn(type) {
                console.log(this.$refs);
                console.log(this.$refs.addPersonalTag);
                if(type == 'industryAddTag') {
                    this.$refs.industryAddTag.showHandleArea();
                } else if(type == 'skilledJobAddTag') {
                    this.$refs.skilledJobAddTag.showHandleArea();
                } else if(type == 'firmResourceAddTag') {
                    this.$refs.firmResourceAddTag.showHandleArea();
                }
            },

            // 滑块验证
            initNc() {
                let _self = this;

                var nc_token = ["FFFF0N0N000000006F4C", (new Date()).getTime(), Math.random()].join(':');
                var NC_Opt = 
                {
                    //声明滑动验证需要渲染的目标元素ID。
                    renderTo: this.tabIndex == 2 ? "#ncContainer1" : "ncContainer",
                    //应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                    appkey: "FFFF0N0N000000006F4C",
                    //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
                    scene: "nc_message",
                    //滑动验证码的主键，请勿将该字段定义为固定值。确保每个用户每次打开页面时，其token值都是不同的。系统默认的格式为：”您的appkey”+”时间戳”+”随机数”。
                    token: nc_token,
                    //滑动条的宽度。
                    customWidth: 334,
                    //业务键字段，可为空。为便于线上问题的排查，建议您按照线上问题定位文档中推荐的方法配置该字段值。
                    trans:{"client":"ldb"},
                    //是否自定义配置底层采集组件。如无特殊场景，请使用默认值（0），即不自定义配置底层采集组件。
                    is_Opt: 0,
                    //语言。PC端Web页面场景默认支持18国语言，详细配置方法请参见自定义文案与多语言文档。
                    language: "cn",
                    //是否启用。一般情况，保持默认值（true）即可。
                    isEnabled: true,
                    //内部网络请求的超时时间。一般情况建议保持默认值（3000ms）。
                    timeout: 3000,
                    //允许服务器超时重复次数，默认5次。超过重复次数后将触发报错。
                    times:5,
                    //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                    callback: function (data) { 
                        _self.ncValidPassed = true;
                        _self.token = nc_token;
                        _self.sessionid = data.csessionid;
                        _self.sig = data.sig;
                    }
                }
                console.log(NC_Opt);
                var nc = new noCaptcha(NC_Opt)
                // console.log(nc)
                //用于自定义文案。详细配置方法请参见自定义文案与多语言文档。
                nc.upLang('cn', {
                    _startTEXT: "请按住滑块，拖动到最右边",
                    _yesTEXT: "验证通过",
                    _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                    _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>"
                })
            },
            // 点击获取验证码按钮
            getCode() {
                if(!this.ncValidPassed) {
                    shortTips('请先拖动滑块完成验证！');
                    return false;
                }

                if(this.codeLoading) {
                    return false;
                }
                
                this.codeLoading = true;
                CertificationService.getCode({
                    mobile : this.ruleForm.Mobile,
                    scene : 5,
                    __RequestVerificationToken : this.$store.state.verificationToken.verificationToken,
                    token: this.token,
                    sessionid: this.sessionid,
                    sig: this.sig,
                    authScene: this.authScene
                }).then(res =>{
                    this.codeGetted = true;
                    shortTips("验证码发送成功，5分钟内未收到请重新获取");
                    this.startCountDown();
                }).catch(err => {
                    this.initNc();
                    this.ncValidPassed = false;
                }).finally(() => {
                    this.codeLoading = false;
                })
            },
            startCountDown() {
                this.timer = setInterval(() => {
                    if(this.countDown > 1) {
                        this.countDown--;
                    }else {
                        this.countDown = 60*5;
                        this.timer && clearInterval(this.timer);
                        this.timer = null;
                        this.initNc();
                        this.ncValidPassed = false;
                    }
                }, 1000)
            },

            handleEditClick(type, option) {
                if(type == 'mobile') {
                    if(option == 'edit') {
                        this.isEditMobile = true;
                    } else {
                        this.isEditMobile = false;
                        // 重置
                        this.resetForm('ruleForm');
                        this.ruleForm.Mobile = this.user.profile.mobile;
                        this.ruleForm.Email = this.user.profile.email;
                        this.initNc();
                        this.ncValidPassed = false;

                        this.helpVisible = false;
                    }
                } else if(type == 'email') {
                    if(option == 'edit') {
                        this.isEditEmail = true;
                    } else {
                        this.isEditEmail = false;
                        // 重置
                        this.resetForm('ruleForm');
                        this.ruleForm.Mobile = this.user.profile.mobile;
                        this.ruleForm.Email = this.user.profile.email;

                        this.helpEmailVisible = false;
                        
                        // if(this.isSendVerifyEmail) {
                        //     this.isSendVerifyEmail = false;
                        //     this.remainSecondSend = 60;
                        // }
                    }
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            },
            
            // 更改手机号提交
            handleChangePhone(formName){
                console.log("点击提交修改电话号码按钮")
                if(this.submitLoading) {
                    return false;
                }
                if(!this.ncValidPassed) {
                    shortTips('请先拖动滑块完成验证！');
                    return false;
                }
                
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.ruleForm.Mobile == this.user.profile.mobile) {
                            return shortTips("输入号码为原手机号!");
                        }
                        this.submitLoading = true;
                        console.log(this.ruleForm.Mobile,this.ruleForm.Code);
                        _request({
                            url: personalCenterUrl.update_mobile,
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded'
                            },
                            data: {
                                Mobile: this.ruleForm.Mobile,
                                Code: this.ruleForm.Code,
                            }
                        }).then(res => {
                            shortTips("手机号更换成功");
                            // this.ruleForm.Mobile = "";
                            // this.ruleForm.Code = "";
                            this.logout();
                        }).catch( err => {
                            console.log(err);
                        }).finally(() => {
                            this.submitLoading = false;
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            // 退出登录
            logout() {
                const params = {
                    accessToken: _getCookie('access_token'),
                    userName: localStorage.getItem('userName'),
                };
                CertificationService
                .loginOut(params)
                .then(()=>{
                    clearCookie('access_token');
                    sessionStorage.removeItem('perpormancesDetailData');
                    localStorage.removeItem('perpormancesDetailData');
                    location.href = '/Account/#/login?ReturnUrl=' + encodeURIComponent(location.pathname + location.search + location.hash);
                })
            },

            // 发送验证邮件
            handleEmailVerify() {
                if(!this.ruleForm.Email) {
                    shortTips('邮箱账号不能为空！');
                    return false;
                }
                this.sendLoading = true;
                _request({
                    url: '/Email/Validate',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        email: this.ruleForm.Email
                    },
                }).then( res => {
                    shortTips('邮箱验证链接已发送');
                    this.isSendVerifyEmail = true;
                    let reSendInterval = setInterval(() => {
                        this.remainSecondSend--;
                        if(this.remainSecondSend == 0) {
                            this.isSendVerifyEmail = false;
                            this.remainSecondSend = 60;
                            clearInterval(reSendInterval);
                        }
                    }, 1000);
                }).finally(() => {
                    this.sendLoading = false;
                })
            },
            dealVerifyDone() {
                // if(this.$route.query.code) { 
                // if(this.$route.query.Tab=='accountSet' && this.$route.query.code) { 
                    _request({
                        url: '/Email/Verifying',
                        method: 'GET',
                        data: {
                            code: this.$route.query.code
                        },
                        apiType: 'oldApi'
                    }).then( res => {
                        this.emailVerifyDone = true;
                        shortTips('恭喜您，您的邮箱验证成功！');
                        this.getUserInfo();
                        location.href = `/Headhunting/#/personalCenter?Tab=accountSet`;
                    }).catch(err => {
                        this.emailVerifyDone = false;
                        shortTips('网络错误，验证失败！');
                    });
                // }
            },

            getUserInfo() {
                var json = {};
                _request({
                    url: personalCenterUrl.my_profile,
                    method: "GET"
                }).then(res => {
                    res.accountBalance = this.$store.state.user.userInfo.accountBalance;
                    res.isBindingPinganAccount = this.$store.state.user.userInfo.isBindingPinganAccount;
                    res.pinganAccount = this.$store.state.user.userInfo.pinganAccount || "";
                    res.isCFUser = this.$store.state.user.userInfo.isCFUser;
                    res.isEmployer = this.$store.state.user.userInfo.isEmployer;

                    this.user = res;
                    this.sloganInput = this.user.profile.slogan;
                    // this.user.profile.avatarUrl = this.$store.state.user.userInfo.avatarUrl;

                    this.ruleForm.Mobile = this.user.profile.mobile;
                    this.ruleForm.Email = this.user.profile.email;
                }).catch( err => {
                    console.log(err);
                });
            },
            updateUserInfo() {
                _request({
                    url: '/user/userInfo',
                    baseURL: "LbdJavaApi",
                    javaProject: "performance",
                    method: "GET",
                    data: {
                        a: 1
                    },
                }).then(res => {
                    this.$store.dispatch('setUserInfo', res);
                    this.user.isBindingPinganAccount = res.isBindingPinganAccount;
                    this.user.pinganAccount = res.pinganAccount || "";
                }).catch( err => {
                    console.log(err);
                });
            },
            handleEditSloganDone() {
                this.loading = true;
                _request({
                    url: personalCenterUrl.update_slogan,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: {
                        slogan: this.sloganInput
                    }
                }).then(res => {
                    shortTips("更新成功");
                    this.user.profile.slogan = this.sloganInput;
                    this.isEditSlogan = false;
                }).catch( err => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
            },
            closeItemNull() {
                this.isHideItemNull = true;
            },
            getIndustryFieldList() {
                _request({
                    url: personalCenterUrl.profile_industries,
                    method: "GET",
                    throwBusinessError: true
                }).then(res => {
                    this.industryField = res;
                }).catch(err => {
                    console.log(err);
                });
            },
            getJobTypeList() {
                _request({
                    url: personalCenterUrl.job_categories,
                    method: "GET",
                    throwBusinessError: true
                }).then(res => {
                    this.jobTypes = res.subJobCategories.slice(1);
                }).catch(res => {
                    if(res.subJobCategories) {
                        this.jobTypes = res.subJobCategories.slice(1);
                    } else {
                        console.log(res);
                    }
                });
            },
            deleteTag(json) {
                this.loading = true;
                let params = {
                    tagId: json.tagId
                };
                if(json.type == 1) {
                    params.isJobCategory = true;
                }
                _request({
                    url: personalCenterUrl.remove_label,
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    data: params
                }).then(res => {
                    switch(json.type) {
                        case 0:
                            this.user.userProfileTags.myIndustryTags.splice(json.index, 1);
                            break;
                        case 1:
                            this.user.userProfileTags.myJobTags.splice(json.index, 1);
                            break;
                        case 2:
                            this.user.userProfileTags.myCustomerTags.splice(json.index, 1);
                            break;
                    }
                }).catch( err => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
            },
            addTag({text, type, id = null}) {
                if(text) {
                    if(text.length > 20) {
                        shortTips("标签不能超过20个字");
                        return;
                    }
                    let params = {
                        tagName: text,
                        type: type
                    }
                    if(type == 1 && id) {
                        params.jobCategoryId = id;
                    }
                    this.loading = true;
                    _request({
                        url: personalCenterUrl.create_label,
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: params
                    }).then(res => {
                        let tag = {};
                        tag.id = res;
                        tag.name = text;
                        switch(type) {
                            case 0: {
                                this.user.userProfileTags.myIndustryTags.push(tag);
                                this.$refs.industryAddTag.addIndustryFieldText = "";
                                break;
                            }
                            case 1:  {
                                this.user.userProfileTags.myJobTags.push(tag);
                                this.$refs.skilledJobAddTag.addJobTypeText = "";
                                break;
                            }
                            case 2:  {
                                this.user.userProfileTags.myCustomerTags.push(tag);
                                this.$refs.firmResourceAddTag.addFirmResourceText = "";
                                break;
                            }
                        }
                    }).catch( err => {
                        console.log(err);
                    }).finally(() => {
                        this.loading = false;
                    });
                }
            },

            handleUploadAvatar() {
                this.$refs['uploadAvatar'].show();
            },
            
            clickTab(tabIndex) {
                this.tabIndex = tabIndex;
                if(tabIndex == 0) {
                    location.href = '/#/personalCenter?Tab=myAdvantage';
                } else if(tabIndex == 1) {
                    location.href = '/#/personalCenter?Tab=personalInfo';
                } else if(tabIndex == 2) {
                    location.href = '/#/personalCenter?Tab=accountSet';
                } else {
                    location.href = '/#/personalCenter';
                }
                if(tabIndex == 2) {
                    this.$nextTick(()=>{
                        this.initNc();
                    })  
                }

                sessionStorage.setItem(`personalCenterTab`, tabIndex);
                if(this.$route.query.state && this.$route.query.state != 'undefined') {
                    location.href = '/#/personalCenter';
                }
            },
            getFirmThirdBoundInfo() {
                this.weChatBindLoading = true;
                CertificationService
                    .FirmThirdBoundInfo()
                    .then(res => {
                        // console.log(res);
                        // [Display(Name = "未授权")]
                        // Unauthorized = 0,

                        // [Display(Name = "待开发")]
                        // ToBeDeveloped = 3,

                        // [Display(Name = "开发中")]
                        // Developing = 5,

                        // [Display(Name = "审核中")]
                        // YunBi = 7,

                        // [Display(Name = "待上线")]
                        // WaitingForLaunch = 9,

                        // [Display(Name = "已上线")]
                        // Online = 13,

                        // [Display(Name = "已解除绑定")]
                        // Unbound = 17,
                        if(res?.status == 13) {
                            this.isAuthorize = true;
                        } else {
                            this.isAuthorize = false;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    }).finally(() => {
                        this.weChatBindLoading = false;
                    });
            },
            getUserThirdBoundInfo() {
                this.weChatBindLoading = true;
                CertificationService
                    .UserThirdBoundInfo()
                    .then(res => {
                        console.log(res);
                        if(res?.userId) {
                            this.isBind = true;
                        } else {
                            this.isBind = false;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    }).finally(() => {
                        this.weChatBindLoading = false;
                    });
            },
            async handleWwLogin() {
                console.log(`授权登录回调地址：${_host.frontSite}/Account`);

                let env = ['development', 'rc'].includes(NODE_ENV) ? 'rc' : (['staging'].includes(NODE_ENV) ? 'stg' : 'pro');
                let appName = env == 'rc' ? 'baza-lbd-hh-rc' : (env == 'stg' ? 'baza-lbd-hh-stg' : 'baza-lbd-hh-pro');

                const state = await this.getState(),
                    appid = 'wx42e1a357d171100a',
                    redirect_uri = encodeURIComponent(`https://tpsi.bazhua.tech/we-com/redirect/service-provider/${appName}`);

                location.href = `https://login.work.weixin.qq.com/wwlogin/sso/login?login_type=ServiceApp&appid=${appid}&redirect_uri=${redirect_uri}&state=${state}`;
            },
            getState() {
                return new Promise((resolve, reject) => {
                    let url;
                    if(NODE_ENV == 'rc') {
                        url = 'https://portal.rc.liebide.com';
                    } else if(NODE_ENV == 'staging') {
                        url = 'https://portal.stg.liebide.com';
                    } else {
                        url = 'https://portal.liebide.com'
                    }
                    let params = {
                        // redirectUrl: 'https://www.lbd.dev.liebide.com:8080/#/personalCenter'
                        redirectUrl: url + `/#/personalCenter`
                    }
                    CertificationService
                        .weComGetStateInfo(params)
                        .then(res => {
                            resolve(res);
                        })
                        .catch(e => {
                            reject(e);
                        });
                });
            },
            // 绑定企业微信
            bindEnterpriseWeChat() {
                this.handleWwLogin();
                // this.$refs.MobileVerifyDialogRef.show(this.user.profile.mobile, 'bind');
            },
            // 解绑企业微信
            unbindEnterpriseWeChat() {
                this.$confirm('确定解绑企业微信吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 校验手机号
                    this.$refs.MobileVerifyDialogRef.show(this.user.profile.mobile, 'unbind');
                }).catch((err) => {
                    console.log(err);          
                });
            }
        },
        directives: {
            over2line(el) {
                if(el.offsetHeight > 100) {
                    el.classList.add('over2line');
                    el.insertAdjacentHTML("afterbegin", `<div class="see-more">展开全部</div>`);

                    el.querySelector(".see-more").addEventListener('click', (e) => {
                        e.stopPropagation();
                        el.classList.remove('over2line');
                        el.removeChild(e.target);
                    }, false);
                }
            },
        },
    }
</script>
<style lang="scss">
    .personal-center {
        height: 100%;
        // padding: 22px 25px;
        padding: 15px;
        overflow: auto;
        // background-color: #fff;
        .input-error {
            border-color: #ff493c !important;
        }
        .col-sm-12{
            padding: 0;
        }

        .tab-panel-list-item {
            .el-input__inner {
                border-radius: 6px !important;
            }
        }
        .personal-info-form {
            .el-form-item {
                .el-form-item__content {
                    margin-left: 0 !important;
                }
            }
        }
        
        .nc-container{
            width: 100%;
            height: auto;
            // margin-bottom: 20px;
            // overflow: hidden;
            .nc_wrapper{
                width: 100%!important;
            }
        }
        .nc-container #nc_1_wrapper{
            width: 100%;
        }
        .nc-container .nc_scale {
            width: 100%;
            height: 40px;
            // background: #e8e8e8; /* 默认背景色 */
            background: #F5F5F5; /* 默认背景色 */
            border-radius: 5px;
            overflow: hidden;
            box-sizing: border-box;
        }
        form.el-form.account-ruleForm {
            .nc-container .nc_scale {
                width: 362px;
            }
        }
        .nc-container .nc_scale .scale_text {
            line-height: 40px;
            // color: #9c9c9c; /* 默认字体颜色 */
            color: #fff; /* 默认字体颜色 */
        }
        .nc-container .nc_scale div.nc_bg { background: #38bc9c; }
        .nc_scale .scale_text2 {
            color: #fff; /* 滑过时的字体颜色 */
        }
        .nc-container .nc-lang-cnt {
            font-size: 14px;
            padding-left: 40px;
        }
        .nc-container .nc_scale .scale_text2 .nc-lang-cnt{
            color: #fff!important;
            padding-left: 0;
        }
        .nc-container .nc_scale .scale_text .nc-lang-cnt{ color: #555; }
        .nc-container .nc_scale span { height: 40px; line-height: 40px; }
        .nc-container .nc_scale .btn_slide { box-sizing: border-box; border-radius: 5px;}
        .nc-container .nc_scale .btn_ok { color: #38bc9c; line-height: 40px; box-sizing: border-box; border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
        .errloading { line-height: 40px; }
    }
</style>
<style lang="scss" scoped>
    .personal-center-contain {
        // width: 1200px;
        // height: auto;
        // margin: 0 auto;
        // margin-top: 60px;
        // padding: 0 5px;
        // font-family: Arial,"微软雅黑";

        height: 100vh;
        // height: 100%;
        display: flex;
        flex-direction: column;
    }
    .icon-17 {
        display: inline-block;
        width: 17px;
        height: 17px;
    }
    // button:active {
    //     box-shadow: inset 0 3px 5px rgba(0,0,0,0.125) !important;
    // }
    button:focus {
        outline: none;
    }
    .user-info {
        margin-bottom: 10px;
        height: 200px;
        // background-color: #fff;
        background: linear-gradient(354deg, #FFFFFF 0%, #F6FBFA 100%);
        width: 100%;
        padding: 34px 50px;
        display: flex;
        font-size: 14px;
        color: #666;
        border-radius: 10px;

        .header-left {
            // width: 50%;
            width: 65%;
            float: left;
            display: flex;
            justify-content: start;
            align-items: center;
        }
        .header-right {
            // width: 50%;
            width: 35%;
            float: right;
            ul {
                height: 100%;
                display: flex;
                // justify-content: space-between;
                justify-content: end;
                align-items: center;
                li {
                    color: #666666;
                    margin: 0  0 0 60px;
                    text-align: center;
                    // img {
                    //     margin-bottom: 12px;
                    // }
                    .bg-circle {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background-color: #fff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        .icon {
                            font-size: 22px;
                            color: #000;
                        }
                    }
                    p {
                        max-width: 200px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }

        &-left {
            margin-right: 36px;
            position: relative;
            .icon-edit {
                width: 20px;
                height: 20px;
                display: none;
                position: absolute;
                z-index: 10;
                top: 0;
                right: 0;
                background: url("~@src/assets/images/personalCenter/icon-edit.png") no-repeat center center;
                &::before{
                    content: "";
                }
            }

            .avatar-edit {
                display: none;
                font-size: 22px;
                color: #fff;
                position: absolute;
                top: 50%;
                left: 50%;  
                margin-top: -11px;
                margin-left: -11px;
                pointer-events: none;
            }
        }
        &-right {
            padding-right: 20px;
        }
        &-avatar {
            width: 108px;
            height: 108px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid rgba(168,168,168,0.44);
            img {
                width: 100%;
                height: 100%
            }
            &:hover {
                cursor: pointer;
                img {
                    opacity: .8;
                }
                & + .avatar-edit {
                    display: block;
                }
            }
        }
        &-realname {
            margin-right: 5px;
            font-size: 24px;
            color: #333333;
            font-weight: bold;
        }
        &-nickname {
            font-size: 16px;
            color: #333333;
            font-weight: bold;
        }
        &-line {
            margin-bottom: 20px;
            font-size: 16px;
            color: #666666;
            display: flex;
            align-items: center;
        }
        &-location {
            .icon {
                font-size: 18px;
            }
        }
        .halving-line {
            margin-left: 12px; 
            margin-right: 12px; 
        }
        .icon-email {
            background: url("~@src/assets/images/personalCenter/icon-email.png") no-repeat center center;
        }
        .icon-mobile {
            background: url("~@src/assets/images/personalCenter/icon-mobile.png") no-repeat center center;
        }
        .icon-location {
            background: url("~@src/assets/images/personalCenter/icon-location.png") no-repeat center center;
        }
        &-icon {
            height: 20px;
            margin-right: 5px;
        }
        &-email, &-mobile, &-location {
            margin-right: 20px;
            display: inline-flex;
            align-items: center;
            line-height: 20px;
        }
        &-slogan {
            margin-bottom: 10px;
            &-text {
                display: flex;
                align-items: center;
                line-height: 20px;
                // color: #38BC9D;
                color: #999999;
                .slogan-text-content {
                    max-width: 94%;
                    margin-right: 2px;
                }
                .slogan-edit {
                    cursor: pointer;
                    &:hover {
                        color: #38BC9C;
                    }
                }
            }
            .icon-edit {
                display: inline-block;
                margin-left: 10px;
                width: 13px;
                height: 13px;
                cursor: pointer;
                background: url("~@src/assets/images/personalCenter/icon-edit-new.png") no-repeat center center;
                // &:hover {
                //     background: url("~@src/assets/images/personalCenter/icon-edit-hover.png") no-repeat center center;
                // }
                &:before {
                    content: '';
                }
            }
            &-form {
                display: flex;
                align-items: center;
            }
            &-input {
                // width: 240px;
                width: 320px;
                height: 60px;
                padding: 10px 12px;
                // resize: both;
                resize: none;
                border: 1px solid #CCCCCC;
                &:hover, &:focus {
                    border-color: #5ecfba;
                }
            }
            &-btn {
                width: 50px;
                height: 32px;
                // margin-left: 12px;
                line-height: 32px;
                text-align: center;
                // background-color: rgb(64, 188, 156);
                color: #38BC9D;
                &-cancel {
                    color: #999999;
                    // background-color: #f3f3f3;
                }
            }
        }
        .perference-tags {
            .perference-job-tabs, .perference-candidate-tabs {
                margin-right: 40px;
                display: inline-block;
                font-weight: bold;
                line-height: 20px;
                color: #666;
                .perference-tab-item {
                    display: inline-block;
                    font-weight: normal;
                    height: 26px;
                    line-height: 26px;
                    padding: 0 8px;
                    background: #f6f6f6;
                    border-radius: 20px;
                    margin: 0 4px;
                }
            }
        }
    }
    .upload-avatar{
        &-header {
            margin-bottom: 20px;
        }
        &-btn {
            padding: 6px 20px;
            background-color: rgb(39, 205, 144);
            color: #fff;
            text-align: center;
            position: relative;
            .el-icon-update {
                color: #ccc;
                margin-right: 5px;
            }
            input {
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
                font-size: 100%;
                opacity: 0;
                z-index: 2;
                cursor: pointer;
            }
        }
        &-text {
            font-size: 14px;
            color: #b9b9b9;
        }
    }
    .tab-panel {
        background-color: #fff;
        width: 100%;
        flex: 1;
        border-radius: 10px;
        &-head {
            // padding: 0 15px;
            padding: 0 20px;
            position: relative;
            margin-bottom: 20px;
            height: 54px;
            line-height: 54px;
            &::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: #EBEBEB;
                position: absolute;
                bottom: 0;
                left: 0;
            }
            &-item {
                display: inline-block;
                // padding: 0 10px;
                // margin: 0 65px;
                margin: 0 25px;
                font-size: 18px;
                line-height: 48px;
                color: #666666;
                font-weight: bold;
                text-align: center;
                box-sizing: border-box;
                cursor: pointer;
            }
            .head-item-activity {
                color: #38bc9d;
                border-bottom: 4px solid #38BC9D;
                position: relative;
                cursor: default;
                &::after {
                    content: "";
                    width: 100%;
                    height: 1px;
                    background-color: #fff;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }
            }
            
            .back {
                cursor: pointer;
                color: #999999;
                &:hover {
                    color: #38bc9d;
                }
            }
        }
        &-body {
            // padding: 0 0 40px;
            padding: 0 20px 40px;
            .my-label {
                line-height: 20px;
                margin-bottom: 6px;
            }
            .option-btn {
                height: 38px;
            }

            .about-me-item-null {
                margin-top: 30px;
                margin-bottom: 24px;
                padding-left: 20px;
                background-color: #fcf8e3;
                font-size: 14px;
                line-height: 50px;
                color: #666;
                position: relative;
                .icon-close {
                    width: 16px;
                    height: 16px;
                    display: inline-block;
                    position: absolute;
                    right: 20px;
                    top: 17px;
                    cursor: pointer;
                    background: url("~@src/assets/images/personalCenter/icon-close-lg.png") no-repeat center center;
                    &:hover {
                        background: url("~@src/assets/images/personalCenter/icon-close-lg-hover.png") no-repeat center center;
                    }
                    &::before {
                        content: "";
                    }
                }
            }
            .about-me-item {
                margin-bottom: 30px;
                &:nth-of-type(1) {
                    margin-top: 24px;
                }
                &-header {
                    padding-bottom: 16px;
                    font-size: 16px;
                    line-height: 22px;
                    color: #333;
                    font-weight: bold;
                    position: relative;
                    display: flex;
                    align-items: center;

                    .user-introduction-change {
                        display: inline-flex;
                        align-items: center;
                        margin-left: 22px;
                        font-size: 14px;
                        color: #999;
                        font-weight: normal;
                        cursor: pointer;
                        position: relative;

                        .icon {
                            margin-right: 4px;
                            font-size: 18px;
                            color: #666;
                        }

                        &:hover {
                            color: #38bc9d;
                            .icon { 
                                color: #38bc9d;
                            }
                        }
                    }
                }
                &-body {
                    &:nth-of-type(1) {
                        padding-top: 25px;
                    }
                    .empty {
                        padding: 8px 0;
                        color: #999;
                        text-align: center;
                    }
                    .user-introduction-text {
                        font-size: 14px;
                        color: #666;
                        line-height: 20px;
                        white-space: pre-line;
                        word-break: break-word;
                    }
                    .user-introduction-text-none {
                        width: 170px;
                        margin: 0 auto;
                        padding-left: 21px;
                        font-size: 14px;
                        color: #b9b9b9;
                        cursor: pointer;
                        position: relative;
                        &:hover {
                            color: #38bc9d;
                            .icon-add-circle {
                                background: url("~@src/assets/images/personalCenter/icon-add-curcle-hover.png") no-repeat center center;
                                background-size: contain;
                            }
                        }
                        .icon-add-circle {
                            width: 15px;
                            height: 15px;
                            position: absolute;
                            top: 2px;
                            left: 0;
                            display: inline-block;
                            background: url("~@src/assets/images/personalCenter/icon-add-curcle.png") no-repeat center center;
                            background-size: contain;
                        }
                    }
                    .user-introduction-form {
                        width: 800px;
                        font-size: 14px;
                        .user-introduction-input {
                            width: 100%;
                            margin-bottom: 15px;
                            padding: 10px;
                            border: 1px solid #e1e1e1;
                            color: #76838f;
                            resize: both;
                        }
                        .user-introduction-btn {
                            width: 100px;
                            padding: 6px 20px;
                            margin-right: 13px;
                            box-sizing: border-box;
                        }
                        .user-introduction-btn-confirm {
                            background-color: #38bc9c;
                            border: 1px solid #38bc9c;
                            color: #fff;
                        }
                        .user-introduction-btn-cancel {
                            color: #666;
                            background-color: #f3f3f3;
                            border-color: #f3f3f3;
                        }
                    }

                    // .user-skilled-job-list{
                    //     max-height: 98px;
                    //     overflow: hidden;
                    //     position: relative;
                    //     .see-more{
                    //         position: absolute;
                    //         bottom: 20px;
                    //         right: 0;
                    //         font-size: 14px;
                    //         color: $primary;
                    //         cursor: pointer;
                    //     }
                    // }
                    .over2line{
                        max-height: 98px;
                        overflow: hidden;
                        position: relative;
                        .see-more{
                            position: absolute;
                            bottom: 20px;
                            right: 0;
                            font-size: 14px;
                            color: $primary;
                            cursor: pointer;
                        }

                    }
                    .height-auto{
                        max-height: auto;
                    }
                }
            }

            .personal-tag-item {
                // background: #F5F5F5;
                margin-right: 10px;
                margin-bottom: 15px;
            }

            // 验证码
            .code-form-item {
                .message-code {
                    display: flex;
                    .get-code{
                        margin-right: 12px;
                        width: 362px;
                        .get-code-btn{
                            // background: #CCCCCC;
                            background: #fff;
                            // color: white;
                            color: #ccc;
                            cursor: pointer;
                            width: 130px;
                            height: 38px;
                            box-sizing: border-box;
                            cursor: not-allowed;
                            border: none;
                            text-align: right;
                            padding-right: 10px;
                        }
                        .green{
                            // background: $primary;
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                }
            }

            .personal-info-form {
                padding-top: 15px;
                width: 622px;
                .el-form-item {
                    &.form-item-left {
                        margin-right: 10px;
                    }
                    &.form-item-right {
                        margin-left: 10px;
                    }
                }
                .personal-info-btn {
                    text-align: right;
                }
                .ncContainer-form-item {
                    margin: 22px 10px 22px 0;
                }
            }

            .change-phone{
                form.el-form.account-ruleForm {
                    // 新手机号
                    .account-input.el-input {
                        width: 362px;
                        margin-right: 12px;
                        input.el-input__inner {
                            &:focus {
                                border-color: #38bc9c;
                            }
                        }
                    }
                    // 没有收到短信验证码？
                    .help-not-receive {
                        position: relative;
                        // padding-left: 14px;
                        .not-received-message-code {
                            display: inline-block;
                            cursor: pointer;
                            // margin-bottom: 20px;
                            margin: 0;
                            font-size: 14px;
                            color: #38BC9C;
                        }
                        .box-card {
                            width: 400px;
                            position: absolute;
                            z-index: 1000;
                            .closeHelp {
                                cursor: pointer;
                                position: absolute;
                                font-size: 20px;
                                color: #999999;
                                top: -5px;
                                right: 10px;
                                &:hover {
                                    color: #ccc;
                                }
                            }
                            .text {
                                font-size: 14px;
                                color: #666666;
                            }
                            // .item {
                            //     padding: 5px 0;
                            // }
                        }
                    }
                    .email-send-btn {
                        cursor: not-allowed;
                        padding: 0 12px;
                        margin-left: 10px;
                        height: 32px;
                        line-height: 32px;
                        border: none;
                        background-color: #38bc9c;
                        border-radius: 5px;
                        color: #fff;
                        font-size: 14px;
                        &:hover {
                            background-color: #36b495;
                        }
                        &:focus {
                            outline: none;
                        }
                    }

                    .email-form-item {
                        padding-top: 22px;
                        .email {
                            display: flex;
                            .email-verify-text {
                                margin-right: 10px;
                                &.verified {
                                    color: $primary;
                                }
                                .icon-verify {
                                    font-size: 18px;
                                    color: $primary;
                                    vertical-align: text-top;
                                }
                            } 
                        }
                    }
                }
            }
            .bind-title {
                padding-top: 12px;
                margin-bottom: 30px;
                font-size: 18px;
                font-weight: 600;
                color: #333;
            }
            .bind-option {  
                display: flex;
                align-items: center;
                .bind-icon {
                    font-size: 40px;
                    margin-right: 18px;
                }
            }
        }
    }
</style>
<style lang="scss" >
.see-more{
    position: absolute;
    bottom: 20px;
    right: 0;
    font-size: 14px;
    color: $primary;
    cursor: pointer;
}
</style>