<template>
    <el-tooltip
        effect="light"
        :content="tag.namePath"
        placement="bottom"
        :disabled="!tag.namePath">
        <div class="personal-tag">
            <span class="personal-tag-text">
                {{tag.name}}
            </span>
            <!-- <span
                class="icon-delete"
                title="删除"
                @click="$emit('delete-tag', {
                    tagId: tag.id,
                    type: type,
                    index: index
                })"
            ></span> -->
            <span class="delete-icon" title="删除" @click="$emit('delete-tag', {
                tagId: tag.id,
                type: type,
                index: index
            })">
                <font-icon href="#icon-close"></font-icon>
            </span>
        </div>
    </el-tooltip>
</template>
<script>
export default {
    props: {
        tag: Object,
        type: Number,
        index: Number
    }
}
</script>
<style lang="scss" scoped>
    .personal-tag {
        width: auto;
        height: 34px;
        display: inline-flex;
        align-items: center;
        color: #999;
        font-size: 14px;
        border-radius: 5px;
        border: 1px solid #CCCCCC;
        padding: 7px 20px;
        &-text {
            cursor: default;
        }
        .delete-icon {
            display: none;
            .icon {
                font-size: 10px;
                color: #38BC9D;
            }
        }
        &:hover {
            cursor: pointer;
            padding-left: 11px;
            padding-right: 15px;
            border: 1px solid #38BC9D;
            color: #38BC9D;
            .delete-icon {
                display: inline-block;
                width: 10px;
                margin-left: 4px;
            }
        }
    }
</style>
