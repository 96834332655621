<template>
    <el-dialog
        title=""
        :visible="dialogVisible"
        width="400px"
        class="mobile-verification-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">
        <div class="content">
            <div class="title">
                <p>请进行手机号验证</p>
                <!-- <p class="tip">不是这个手机号？</p> -->
            </div>
            <el-form 
                :model="mobileVerifyForm" 
                :rules="mobileVerifyRules" 
                ref="mobileVerifyForm" 
                class="mobileVerify-ruleForm">
                <el-form-item prop="Mobile">
                    <el-input 
                        v-model.trim="mobileVerifyForm.Mobile"
                        placeholder="输入手机号"
                        disabled
                    >
                        <font-icon slot="prefix" class="phone-icon" href="#icon-shouji"></font-icon>
                    </el-input>
                </el-form-item>

                <el-form-item>
                    <div id="ncContainer2" class="nc-container"></div>
                </el-form-item>

                <el-form-item prop="Code">
                    <el-input 
                        v-model="mobileVerifyForm.Code"
                        placeholder="输入短信验证码"
                        class="get-code"
                        >
                        <font-icon slot="prefix" class="code-icon" href="#icon-yanzhengma"></font-icon>
                        <el-button
                            slot="suffix"
                            class="get-code-btn"
                            :disabled="!canGetCode || !!timer" 
                            @click="getCode"
                            type="button"
                            :loading="codeLoading"
                            :class="{'green': ncValidPassed && canGetCode}"
                            >
                                <template v-if="!codeGetted">获取验证码</template>
                                <template v-else>{{timer ? `${countDown}秒后` : ''}}重新获取</template>
                        </el-button>
                    </el-input>
                </el-form-item>
            </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
            <el-button type="primary" @click="submitForm('mobileVerifyForm')" :loading="sureLoading">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { validateMobile } from '#/js/util/validate.js';
import CertificationService from '#/js/service/certificationService.js';

export default {
    name: 'BazaLbdPortalFrontendMobileVerificationDialog',

    data() {
        return {
            type: '',
            shortToken: '',
            dialogVisible: false,
            sureLoading: false,
            mobileVerifyForm: {
                Mobile: "13900139000",
                Code: "",
            },
            mobileVerifyRules: {
                Mobile: [
                    { required: true, message: '请输入手机号', trigger: ['blur', 'change'] },
                    { validator: validateMobile, trigger: ['blur', 'change'] }
                ],
                Code: [
                    { required: true, message: '请输入短信验证码', trigger: ['blur', 'change'] },
                    { pattern: /^[0-9]{4}$/, message: '短信验证码必须为4位数字', trigger: ['blur', 'change'] }
                ],
            },
            codeLoading: false,
            codeGetted: false,
            canGetCode: false,
            canReGetCode: false,
            countDown: 60*5,
            timer: null,
            ncValidPassed: false,
            token: '',
            sessionid: '',
            sig: '',
            authScene: 'nc_register',
        };
    },

    mounted() {

    },

    watch: {
        'mobileVerifyForm.Mobile': function(val) {
            if(val) {
                this.canGetCode = true;
            }else {
                this.canGetCode = false;
            }
        }
    },

    methods: {
        show(mobile, type, shortToken) {
            this.mobileVerifyForm.Mobile = mobile;
            this.type = type;
            this.shortToken = shortToken;
            
            this.dialogVisible = true;
            this.$nextTick(()=>{
                this.initNc();
            })
        },
        handleClose() {
            this.dialogVisible = false;
        },
        submitForm(formName) {
            if (this.sureLoading) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.sureLoading = true;

                    if(this.type == 'bind') { // 绑定
                        let params = {
                            shortToken: this.shortToken,
                            VerifyCode: this.mobileVerifyForm.Code
                        }
                        CertificationService.WeComUserBinding(params)
                        .then(res =>{
                            // console.log(res);
                            this.dialogVisible = false;
                            shortTips("绑定成功");
                            this.$emit('bind-success');
                        }).catch(err => {
                            console.log(err);
                        }).finally(() => {
                            this.sureLoading = false;
                        })
                    } else { // 解绑
                        let params = {
                            VerifyCode: this.mobileVerifyForm.Code
                        }
                        CertificationService.WeComUserUnbound(params)
                        .then(res =>{
                            console.log(res);
                            shortTips("解绑成功");
                        }).catch(e => {
                            // console.log(e);
                            const msg = e.error.code == "10002" ?  
                                "验证码不正确 ，请重新输入！": 
                                e.error.description;
                            shortTips(msg);
                        }).finally(() => {
                            this.sureLoading = false;
                        })
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        // 滑块验证
        initNc() {
            let _self = this;

            var nc_token = ["FFFF0N0N000000006F4C", (new Date()).getTime(), Math.random()].join(':');
            var NC_Opt = 
            {
                //声明滑动验证需要渲染的目标元素ID。
                renderTo: "#ncContainer2",
                //应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
                appkey: "FFFF0N0N000000006F4C",
                //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
                scene: "nc_message",
                //滑动验证码的主键，请勿将该字段定义为固定值。确保每个用户每次打开页面时，其token值都是不同的。系统默认的格式为：”您的appkey”+”时间戳”+”随机数”。
                token: nc_token,
                //滑动条的宽度。
                customWidth: 334,
                //业务键字段，可为空。为便于线上问题的排查，建议您按照线上问题定位文档中推荐的方法配置该字段值。
                trans:{"client":"ldb"},
                //是否自定义配置底层采集组件。如无特殊场景，请使用默认值（0），即不自定义配置底层采集组件。
                is_Opt: 0,
                //语言。PC端Web页面场景默认支持18国语言，详细配置方法请参见自定义文案与多语言文档。
                language: "cn",
                //是否启用。一般情况，保持默认值（true）即可。
                isEnabled: true,
                //内部网络请求的超时时间。一般情况建议保持默认值（3000ms）。
                timeout: 3000,
                //允许服务器超时重复次数，默认5次。超过重复次数后将触发报错。
                times:5,
                //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                callback: function (data) { 
                    _self.ncValidPassed = true;
                    _self.token = nc_token;
                    _self.sessionid = data.csessionid;
                    _self.sig = data.sig;
                }
            }
            var nc = new noCaptcha(NC_Opt)
            // console.log(nc)
            //用于自定义文案。详细配置方法请参见自定义文案与多语言文档。
            nc.upLang('cn', {
                _startTEXT: "请按住滑块，拖动到最右边",
                _yesTEXT: "验证通过",
                _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
                _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>"
            })
        },
        // 点击获取验证码按钮
        getCode() {
            if(!this.ncValidPassed) {
                shortTips('请先拖动滑块完成验证！');
                return false;
            }

            if(this.codeLoading) {
                return false;
            }
            
            this.codeLoading = true;
            let params = {
                scene : 9,
                token: this.token,
                sessionid: this.sessionid,
                sig: this.sig,
                authScene: this.authScene
            }
            CertificationService.GetMobileVerifyCode(params)
            .then(res =>{
                this.codeGetted = true;
                shortTips("验证码发送成功，5分钟内未收到请重新获取");
                this.startCountDown();
            }).catch(err => {
                this.initNc();
                this.ncValidPassed = false;
            }).finally(() => {
                this.codeLoading = false;
            })
        },
        startCountDown() {
            this.timer = setInterval(() => {
                if(this.countDown > 1) {
                    this.countDown--;
                }else {
                    this.countDown = 60*5;
                    this.timer && clearInterval(this.timer);
                    this.timer = null;
                    this.initNc();
                    this.ncValidPassed = false;
                }
            }, 1000)
        },
    },
};
</script>

<style lang="scss">
.personal-center {
    .mobile-verification-dialog {
        .nc-container{
            width: 100%;
            height: auto;
            // margin-bottom: 20px;
            // overflow: hidden;
            .nc_wrapper{
                width: 100%!important;
            }
        }
        .nc-container #nc_1_wrapper{
            width: 100%;
        }
        .nc-container .nc_scale {
            width: 100%;
            height: 46px;
            // background: #e8e8e8; /* 默认背景色 */
            background: #F5F5F5; /* 默认背景色 */
            border-radius: 5px;
            overflow: hidden;
            box-sizing: border-box;
        }
        .nc-container .nc_scale .scale_text {
            line-height: 46px;
            // color: #9c9c9c; /* 默认字体颜色 */
            color: #fff; /* 默认字体颜色 */
        }
        .nc-container .nc_scale div.nc_bg { background: #38bc9c; }
        .nc_scale .scale_text2 {
            color: #fff; /* 滑过时的字体颜色 */
        }
        .nc-container .nc-lang-cnt {
            font-size: 14px;
            padding-left: 40px;
        }
        .nc-container .nc_scale .scale_text2 .nc-lang-cnt{
            color: #fff!important;
            padding-left: 0;
        }
        .nc-container .nc_scale .scale_text .nc-lang-cnt{ color: #555; }
        .nc-container .nc_scale span { height: 46px; line-height: 46px; }
        .nc-container .nc_scale .btn_slide { box-sizing: border-box; border-radius: 5px;}
        .nc-container .nc_scale .btn_ok { color: #38bc9c; line-height: 46px; box-sizing: border-box; border-top-right-radius: 4px; border-bottom-right-radius: 4px;}
        .errloading { line-height: 46px; }
    }
}
</style>
<style lang="scss" scoped>
.mobile-verification-dialog {
    /deep/.el-dialog {
        border-radius: 12px;
        .el-dialog__header {
            border-bottom: none;
        }
        .el-dialog__body {
            .content {
                p {
                    margin: 0;
                }
                .title {
                    color: #333;
                    font-size: 18px;
                    font-weight: 600;
                    .tip {
                        color: #38bc9c;
                        font-size: 14px;
                        font-weight: normal;
                    }
                }
                .mobileVerify-ruleForm {
                    margin-top: 42px;
                    .el-input__inner {
                        height: 46px;
                        line-height: 46px;
                    }
                    .el-input__suffix {
                        line-height: 46px;
                    }
                    
                    .el-input__inner:focus + .el-input__prefix .icon {
                        color: #000; /* 更改颜色 */
                    }
                    .el-input--prefix {
                        .el-input__inner {
                            padding-left: 35px;
                        }
                    }
                    .el-input__prefix {
                        left: 10px !important;
                        .phone-icon {
                            font-size: 20px;
                            margin-top: 5px;
                        }
                        .code-icon {
                            font-size: 20px;
                            margin-top: 5px;
                        }
                    }

                    .get-code{
                        .get-code-btn{
                            // background: #CCCCCC;
                            background: #fff;
                            // color: white;
                            color: #ccc;
                            cursor: pointer;
                            width: 130px;
                            height: 38px;
                            box-sizing: border-box;
                            cursor: not-allowed;
                            border: none;
                            text-align: right;
                            padding-right: 10px;
                        }
                        .green{
                            // background: $primary;
                            color: $primary;
                            cursor: pointer;
                        }
                    }
                }
            }
            .option-btn {
                text-align: right;
            }
        }
    }
}
</style>