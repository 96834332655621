<template>
    <div class="add-personal-tag">
        <!-- <div class="add-personal-tag-btn-none" title="点击添加" v-if="isNone && !isAddPersonalTab" @click="showHandleArea">
            <i class="icon-add-circle"></i>{{type == 0 ? '添加我擅长的行业领域' : (type == 1 ? '添加我擅长的职位' : '添加我的公司资源')}}
        </div>
        <div class="add-personal-tag-btn" title="点击添加" v-if="!isNone && !isAddPersonalTab" @click="showHandleArea">
            <i class="icon-add"></i>添加
        </div> -->
        <div class="add-personal-tag-handle" v-if="isAddPersonalTab && type == 0">
            <i class="icon-close" @click="closeHandleArea"></i>
            <div class="handle-choose">
                <template v-if="industryField.length > 0">
                    <span
                        class="industry-field-text"
                        v-for="(industryFieldItem, index) in industryField"
                        :key="index"
                        @click="$emit('add-tag', {
                            text: industryFieldItem,
                            type: type
                        })">
                        {{industryFieldItem}}
                    </span>
                </template>
                <div class="data-null" v-else>
                    暂无数据
                </div>
            </div>
            <!-- <input class="handle-input" type="text" placeholder="输入你擅长的行业领域" v-model="addIndustryFieldText"/>
            <button
                class="handle-btn"
                @click="$emit('add-tag', {
                    text: addIndustryFieldText,
                    type: type
                })">
                新增
            </button> -->
        </div>
        <div class="add-personal-tag-handle" v-if="isAddPersonalTab && type == 1">
            <i class="icon-close" @click="closeHandleArea"></i>
            <div class="handle-choose">
                <template v-if="jobTypes.length > 1">
                    <div
                        class="job-list job-level-high-list"
                        v-for="highLevel in jobTypes"
                        :key="highLevel.id">
                        <h3 class="job-item-header job-level-high">
                            <span>{{highLevel.name}}</span>
                            <span class="line"></span>
                        </h3>
                        <div
                            class="job-list"
                            v-for="jobType in highLevel.subJobCategories"
                            :key="jobType.id">
                            <h3 class="job-item-header">
                                {{jobType.name}}
                            </h3>
                            <span
                                class="job-item-text"
                                v-for="subJobCategory in jobType.subJobCategories"
                                :key="subJobCategory.id"
                                @click="$emit('add-tag', {
                                    text: subJobCategory.name,
                                    type: type,
                                    id: subJobCategory.id
                                })">
                                {{subJobCategory.name}}
                            </span>
                        </div>
                    </div>
                </template>
                <div class="data-null" v-else>
                    暂无数据
                </div>
            </div>
            <!-- <input class="handle-input" type="text" placeholder="输入你擅长的职位" v-model="addJobTypeText"/>
            <button
                class="handle-btn"
                @click="$emit('add-tag', {
                    text: addJobTypeText,
                    type: type
                })">
                新增
            </button> -->
        </div>

        <div class="add-personal-tag-handle" v-if="isAddPersonalTab && type == 2">
            <i class="icon-close" @click="closeHandleArea"></i>
            <input class="handle-input" type="text" placeholder="输入公司名称" v-model="addFirmResourceText"/>
            <button
                class="handle-btn"
                @click="$emit('add-tag', {
                    text: addFirmResourceText,
                    type: type
                })">
                新增
            </button>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            isAddPersonalTab: false,
            addIndustryFieldText: "",
            addJobTypeText: "",
            addFirmResourceText: ""
        }
    },
    props: {
        isNone: Boolean,
        type: Number,
        industryField: Array,
        jobTypes: Array
    },
    mounted() {
    },
    methods: {
        showHandleArea() {
            this.isAddPersonalTab = true;
        },
        closeHandleArea() {
            this.isAddPersonalTab = false;
            this.addIndustryFieldText = "";
            this.addJobTypeText = "";
            this.addFirmResourceText = "";
        },
    }
}
</script>
<style lang="scss" scoped>
    .add-personal-tag {
        &-btn-none {
            width: 170px;
            margin: 0 auto;
            padding-left: 21px;
            font-size: 14px;
            color: #b9b9b9;
            cursor: pointer;
            position: relative;
            &:hover {
                color: #38bc9d;
                .icon-add-circle {
                    background: url("~@src/assets/images/personalCenter/icon-add-curcle-hover.png") no-repeat center center;
                }
            }
            .icon-add-circle {
                width: 15px;
                height: 15px;
                position: absolute;
                top: 2px;
                left: 0;
                display: inline-block;
                background: url("~@src/assets/images/personalCenter/icon-add-curcle.png") no-repeat center center;
                background-size: contain;
            }
        }
        &-btn {
            cursor: pointer;
            width: 80px;
            height: 30px;
            padding: 8px 0;
            color: #38bc9d;
            font-size: 12px;
            line-height: 1;
            text-align: center;
            border: 1px solid #38bc9d;
            box-sizing: border-box;
            border-radius: 15px;
            .icon-add {
                width: 12px;
                height: 12px;
                margin-right: 4px;
                display: inline-block;
                background: url("~@src/assets/images/personalCenter/icon-add.png") no-repeat center center;
                &::before {
                    content: "";
                }
            }
        }
        &-handle {
            position: relative;
            // padding: 36px 0 30px 20px;
            // width: 800px;
            padding: 30px 20px;
            width: 100%;
            background-color: #f5fbfa;
            .icon-close {
                cursor: pointer;
                width: 16px;
                height: 16px;
                display: inline-block;
                position: absolute;
                top: 20px;
                right: 20px;
                background: url("~@src/assets/images/personalCenter/icon-close-lg.png") no-repeat center center;
                &:hover {
                    background: url("~@src/assets/images/personalCenter/icon-close-lg-hover.png") no-repeat center center;
                }
                &::before {
                    content: "";
                }
            }
            .handle-choose {
                margin-top: 10px;
                width: 100%;
                max-height: 500px;
                overflow-y: scroll;
                .industry-field-text {
                    display: inline-block;
                    padding-top: 5px;
                    padding-right: 14px;
                    font-size: 14px;
                    line-height: 20px;
                    color: #666;
                    cursor: pointer;
                    &:hover {
                        color: #38bc9d;
                    }
                }
                .job-list {
                    margin-bottom: 24px;
                    // width: 732px;
                    overflow-x: hidden;
                    &.job-level-high-list {
                        margin-bottom: 28px;
                        &:nth-last-of-type(1) {
                            margin-bottom: 0;
                        }
                    }
                    .job-item-header {
                        color: #333;
                        font-size: 16px;
                        margin-bottom: 14px;
                        &.job-level-high {
                            font-weight: bold;
                            color: $primary;
                            margin-bottom: 24px;
                            position: relative;
                            // display: inline-block;
                            display: flex;
                            align-items: center;
                            
                            .line {
                                flex: 1;
                                margin-left: 10px;
                                margin-right: 20px;
                                display: inline-block;
                                height: 2px;
                                width: 100%;
                                background-color: $primary;
                            }
                        }
                    }
                    .job-item-text {
                        display: inline-block;
                        margin-right: 10px;
                        font-size: 14px;
                        line-height: 20px;
                        color: #b9b9b9;
                        cursor: pointer;
                        &:hover {
                            color: #38bc9d;
                        }
                    }
                }
            }
            .handle-input {
                height: 32px;
                line-height: 32px;
                margin-top: 20px;
                padding: 5px;
                box-sizing: border-box;
                border: 1px solid #38bc9d;
                color: #767676;
            }
            .handle-btn {
                display: inline-block;
                padding: 0 20px;
                font-size: 14px;
                height: 32px;
                line-height: 32px;
                color: #fff;
                background-color: #38bc9c;
                border: 1px solid #38bc9c;
                &:focus {
                    outline: none;
                }
                &:active {
                    box-shadow: inset 0 2px 10px rgba(0,0,0,0.2);
                }
            }
            .data-null {
                font-size: 16px;
                color: #333;
            }
        }
    }
</style>
