var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      attrs: {
        effect: "light",
        content: _vm.tag.namePath,
        placement: "bottom",
        disabled: !_vm.tag.namePath,
      },
    },
    [
      _c("div", { staticClass: "personal-tag" }, [
        _c("span", { staticClass: "personal-tag-text" }, [
          _vm._v("\n            " + _vm._s(_vm.tag.name) + "\n        "),
        ]),
        _c(
          "span",
          {
            staticClass: "delete-icon",
            attrs: { title: "删除" },
            on: {
              click: function ($event) {
                return _vm.$emit("delete-tag", {
                  tagId: _vm.tag.id,
                  type: _vm.type,
                  index: _vm.index,
                })
              },
            },
          },
          [_c("font-icon", { attrs: { href: "#icon-close" } })],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }