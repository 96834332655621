<template>
    <div class="personal-info">
        <el-form :model="infoForm" :rules="rules" ref="infoForm" label-width="100px" class="personal-info-form">
            <el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item prop="nickName" class="form-item-left">
                            <div class="my-label">昵称</div>
                            <el-input v-model="infoForm.nickName" placeholder="请输入昵称" :disabled="!isEditInfo"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="realName" class="form-item-right">
                            <div class="my-label">姓名</div>
                            <el-input v-model="infoForm.realName" placeholder="请输入姓名" :disabled="!isEditInfo"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item prop="introduction">
                <div class="my-label">个人简介</div>
                <el-input type="textarea" rows="5" v-model="infoForm.introduction" placeholder="请输入个人简介" :disabled="!isEditInfo"></el-input>
            </el-form-item>
            <el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item prop="title" class="form-item-left">
                            <div class="my-label">职位信息</div>
                            <el-input v-model="infoForm.title" placeholder="请输入职位" :disabled="!isEditInfo"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item class="form-item-right">
                            <div class="my-label">办公地点</div>
                            <el-input v-model="infoForm.location" placeholder="请选择办公地点" :disabled="true" ></el-input>
                            <template v-if="false">
                                <el-row :gutter="20">
                                    <el-col :span="12">
                                        <el-form-item prop="provinceSelect">
                                            <el-select 
                                                v-model="infoForm.provinceSelect" 
                                                placeholder="请选择省"  
                                                @change="handleProvinceSelect">
                                                <el-option
                                                    v-for="item in provinces.slice(1)"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-form-item prop="citySelect">
                                            <el-select 
                                                v-model="infoForm.citySelect" 
                                                placeholder="请选择城市">
                                                <el-option
                                                    v-for="item in cities"
                                                    :key="item.value"
                                                    :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <div class="personal-info-btn">
                <template v-if="!isEditInfo">
                    <el-button
                        class="option-btn"
                        @click="handleEditClick('personal-info', 'edit')"
                    >编辑</el-button>
                </template>
                <template v-if="isEditInfo">
                    <el-button
                        class="option-btn"
                        @click="handleEditClick('personal-info', 'cancel')"
                    >取消</el-button>
                    <el-button
                        class="option-btn"
                        type="primary"
                        :loading="submitLoading"
                        @click="handleSaveClick('infoForm')"

                    >保存</el-button>
                </template>
            </div>
        </el-form>
        <el-form :model="inquireForm" :rules="rules" ref="inquireForm" label-width="100px" class="personal-info-form">
            <el-form-item>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item prop="inquirePhone" class="form-item-left">
                            <div class="my-label">寻访专用手机号</div>
                            <el-input v-model="inquireForm.inquirePhone" placeholder="请输入寻访专用手机号" :disabled="!isEditInquireMobile"></el-input>
                        </el-form-item>
                        <!-- 滑块验证 -->
                        <!-- <el-form-item class="ncContainer-form-item" v-show="isEditInquireMobile">
                            <div class="col-sm-12">
                                <div id="ncContainer" class="nc-container"></div>
                            </div>
                        </el-form-item> -->
                        <el-form-item prop="Code" class="code-form-item" v-show="isEditInquireMobile && user.profile.mobile != inquireForm.inquirePhone">
                            <div class="message-code">
                                <el-input 
                                    v-model="inquireForm.Code"
                                    placeholder="输入验证码"
                                    class="get-code">
                                    <!-- :class="{'green': ncValidPassed && canGetCode}" -->
                                    <el-button
                                        slot="suffix"
                                        class="get-code-btn"
                                        :disabled="!canGetCode || !!timer" 
                                        @click="getCode"
                                        type="button"
                                        :loading="codeLoading"
                                        :class="{'green': canGetCode && !timer}">
                                            <template v-if="!codeGetted">获取验证码</template>
                                            <template v-else>{{timer ? `${countDown}秒后` : ''}}重新获取</template>
                                    </el-button>
                                </el-input>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item prop="inquireMobile" class="form-item-right">
                            <div class="my-label">寻访专用电话</div>
                            <el-input v-model="inquireForm.inquireMobile" placeholder="请输入专用电话 例如：0387-6543256" :disabled="!isEditInquireMobile"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <div class="personal-info-btn">
                <template v-if="!isEditInquireMobile">
                    <el-button
                        class="option-btn"
                        @click="handleEditClick('inquire-mobile', 'edit')"
                    >{{ inquireForm.inquirePhone || inquireForm.inquireMobile ? '编辑' : '添加' }}</el-button>
                </template>
                <template v-if="isEditInquireMobile">
                    <el-button
                        class="option-btn"
                        @click="handleEditClick('inquire-mobile', 'cancel')"
                    >取消</el-button>
                    <el-button
                        class="option-btn"
                        type="primary"
                        :loading="submitLoading"
                        @click="handleSaveInquireMobile('inquireForm')"

                    >保存</el-button>
                </template>
            </div>
        </el-form>
    </div>
</template>

<script>
import { validateMobile } from '#/js/util/validate.js';
import { province as provinceList } from "#/js/config/provinceThreeData.json";
import { personal_center as personalCenterUrl } from '#/js/config/api.json';
// import CertificationService from '#/js/service/certificationService.js';
import phoneService from '#/js/service/phoneService.js';
import provinceCity from '#/js/util/provinceCityConfig/provinceCity.json';
import cityProvinceMap from '#/js/config/cityProvinceMap.json';

export default {
    props: {
        user: {
            type: Object,
            default: {}
        }
    },
    data() {
        var validateNickName = (rule, value, callback) => {
            if(!/^[A-Za-z0-9_]{4,30}$/.test(value)) {
                callback(new Error("4-30个字符，支持英文、数字、下划线"));
            } else if(/^\d{4,}$/.test(value)) {
                callback(new Error("不可以使用纯数字作为昵称"));
            }else {
                callback();
            }
        };
        var validateMsgCode = (rule, value, callback) => {
            if(!value && this.inquireForm.inquirePhone != this.initInquirePhone) {
                callback(new Error('更改寻访手机号，请输入短信验证码'));
            } else if (value && !/^[0-9]{4}$/.test(value)) {
                callback(new Error('短信验证码必须为4位数字'));
            } else {
                callback();
            }
        };
        var validateInquireMobile = (rule, value, callback) => {
            if (value && !/^\d{2,5}-\d{7,8}$/.test(value)) {
                callback(new Error('寻访专用电话格式不正确，请重新输入'));
            } else {
                callback();
            }
        };
        return {
            submitLoading: false, // 提交更换手机号按钮的loading样式
            codeLoading: false, // 获取验证码按钮的loading样式
            codeGetted: false, // 获取到验证码时
            canGetCode: false, // 是否可以获取验证码
            countDown: 60*5, // 验证码倒计时
            timer: null,
            ncValidPassed: false, // 是否通过滑块验证
            token: '',
            sessionid: '',
            sig: '',
            authScene: 'nc_message',
            infoForm: {
                nickName: '',
                realName: '',
                introduction: '',
                title: '',
                location: '',
                provinceSelect: '',
                citySelect: '',
            },
            initInquirePhone: '',
            initInquireMobile: '',
            inquireForm: {
                inquirePhone: '',
                Code: '',
                inquireMobile: '',
            },
            isEditInfo: false,
            provinces: provinceList,
            cities: [],
            isEditInquireMobile: false,
            isEditMobile: false,
            rules: {
                nickName: [
                    { required: true, message: '请输入昵称', trigger: ['blur', 'change']},
                    { validator: validateNickName, trigger: ['blur', 'change'] }
                ],
                realName: [
                    { required: true, message: '请输入姓名', trigger: ['blur', 'change'] },
                ],
                title: [
                    { required: true, message: '请输入职位', trigger: ['blur', 'change'] },
                ],
                introduction: [
                    { required: true, message: '请输入个人简介', trigger: ['blur', 'change'] },
                ],
                provinceSelect: [
                    { required: true, message: '请选择所在省', trigger: ['blur', 'change'] },
                ],
                citySelect: [
                    { required: true, message: '请选择所在城市', trigger: ['blur', 'change'] },
                ],

                inquirePhone: [
                    { required: true, message: '请输入寻访专用手机号', trigger: ['blur', 'change'] },
                    { validator: validateMobile, trigger: ['blur', 'change'] }
                ],
                Code: [
                    // { required: true, message: '请输入短信验证码', trigger: ['blur', 'change'] },
                    // { pattern: /^[0-9]{4}$/, message: '短信验证码必须为4位数字', trigger: ['blur', 'change'] }
                    { validator: validateMsgCode, trigger: ['blur', 'change'] }
                ],
                inquireMobile: [
                    // { required: true, message: '请输入寻访专用电话', trigger: ['blur', 'change'] },
                    { validator: validateInquireMobile, trigger: ['blur', 'change'] }
                ],
            },
        };
    },

    watch: {
        user(val) {
            if(val.profile) {
                this.infoForm.nickName = val.profile.nickname;
                this.infoForm.realName = val.profile.realName;
                this.infoForm.introduction = val.profile.introduction;
                this.infoForm.title = val.profile.title;
                // this.infoForm.location = val.profile.location;
                this.infoForm.location = provinceCity[val.profile.frequentLocation] || '未知';

                // this.infoForm.provinceSelect = val.profile.province;
                // this.infoForm.citySelect = val.profile.city;
                this.infoForm.citySelect = val.profile.frequentLocation;
                this.infoForm.provinceSelect = +cityProvinceMap[val.profile.frequentLocation];
                this.cities = this.provinces.find(province => {
                    return province.value == this.infoForm.provinceSelect;
                })?.children;
            }
        },
        'infoForm.provinceSelect': function(val) {
            console.log(val);
            if(val) {
                this.cities = provinceList.find(province => {
                    return province.value == this.infoForm.provinceSelect;
                })?.children;
            } else {
                this.cities = [];
            }
        },
        // 判断是否填写了电话号码，电话号码填写正确了才可以点击获取验证码
        'inquireForm.inquirePhone': function(val) {
            if(/^(1[3-9])\d{9}$/.test(val)) {
                this.canGetCode = true;
            } else {
                this.canGetCode = false;
            }
        },
    },


    created() {
        this.getPhoneList();
    },

    mounted() {
        // this.$nextTick(() => {
        //     this.initNc();
        // })
    },

    methods: {
        getPhoneList() {
            phoneService.getPhoneList()
            .then(res => {
                // console.log(res);
                res.forEach(el => {
                    if(el.numberType == 0) {
                        this.initInquirePhone = el.number;
                    } else if(el.numberType == 1) {
                        this.initInquireMobile = el.number;
                    } 
                })

                this.inquireForm.inquirePhone = this.initInquirePhone;
                this.inquireForm.inquireMobile = this.initInquireMobile;
            })
        },
        // // 滑块验证
        // initNc() {
        //     let _self = this;

        //     var nc_token = ["FFFF0N0N000000006F4C", (new Date()).getTime(), Math.random()].join(':');
        //     var NC_Opt = 
        //     {
        //         //声明滑动验证需要渲染的目标元素ID。
        //         renderTo: this.tabIndex == 2 ? "#ncContainer1" : "ncContainer",
        //         //应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        //         appkey: "FFFF0N0N000000006F4C",
        //         //使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
        //         scene: "nc_message",
        //         //滑动验证码的主键，请勿将该字段定义为固定值。确保每个用户每次打开页面时，其token值都是不同的。系统默认的格式为：”您的appkey”+”时间戳”+”随机数”。
        //         token: nc_token,
        //         //滑动条的宽度。
        //         customWidth: 334,
        //         //业务键字段，可为空。为便于线上问题的排查，建议您按照线上问题定位文档中推荐的方法配置该字段值。
        //         trans:{"client":"ldb"},
        //         //是否自定义配置底层采集组件。如无特殊场景，请使用默认值（0），即不自定义配置底层采集组件。
        //         is_Opt: 0,
        //         //语言。PC端Web页面场景默认支持18国语言，详细配置方法请参见自定义文案与多语言文档。
        //         language: "cn",
        //         //是否启用。一般情况，保持默认值（true）即可。
        //         isEnabled: true,
        //         //内部网络请求的超时时间。一般情况建议保持默认值（3000ms）。
        //         timeout: 3000,
        //         //允许服务器超时重复次数，默认5次。超过重复次数后将触发报错。
        //         times:5,
        //         //前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        //         callback: function (data) { 
        //             _self.ncValidPassed = true;
        //             _self.token = nc_token;
        //             _self.sessionid = data.csessionid;
        //             _self.sig = data.sig;
        //         }
        //     }
        //     console.log(NC_Opt);
        //     var nc = new noCaptcha(NC_Opt)
        //     // console.log(nc)
        //     //用于自定义文案。详细配置方法请参见自定义文案与多语言文档。
        //     nc.upLang('cn', {
        //         _startTEXT: "请按住滑块，拖动到最右边",
        //         _yesTEXT: "验证通过",
        //         _error300: "哎呀，出错了，点击<a href=\"javascript:__nc.reset()\">刷新</a>再来一次",
        //         _errorNetwork: "网络不给力，请<a href=\"javascript:__nc.reset()\">点击刷新</a>"
        //     })
        // },
        // 点击获取验证码按钮
        getCode() {
            // if(!this.ncValidPassed) {
            //     shortTips('请先拖动滑块完成验证！');
            //     return false;
            // }

            if(this.codeLoading) {
                return false;
            }
            
            this.codeLoading = true;
            // CertificationService.getCode({
            //     mobile : this.ruleForm.Mobile,
            //     scene : 5,
            //     __RequestVerificationToken : this.$store.state.verificationToken.verificationToken,
            //     token: this.token,
            //     sessionid: this.sessionid,
            //     sig: this.sig,
            //     authScene: this.authScene
            // }).then(res =>{
            //     this.codeGetted = true;
            //     shortTips("验证码发送成功，5分钟内未收到请重新获取");
            //     this.startCountDown();
            // }).catch(err => {
            //     this.initNc();
            //     this.ncValidPassed = false;
            // }).finally(() => {
            //     this.codeLoading = false;
            // })
            phoneService.getMsgCode({
                mobileNumber: this.inquireForm.inquirePhone
            }).then(res => {
                this.codeGetted = true;
                shortTips("验证码发送成功，5分钟内未收到请重新获取");
                this.startCountDown();
            }).finally(() => {
                this.codeLoading = false;
            });
        },
        startCountDown() {
            this.timer = setInterval(() => {
                if(this.countDown > 1) {
                    this.countDown--;
                }else {
                    this.countDown = 60*5;
                    this.timer && clearInterval(this.timer);
                    this.timer = null;
                    // this.initNc();
                    // this.ncValidPassed = false;
                }
            }, 1000)
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleEditClick(type, option) {
            // console.log(type);
            // console.log(option);
            if(type == 'personal-info') {
                    if(option == 'edit') {
                    this.isEditInfo = true;
                } else {
                    this.isEditInfo = false;
                    // 重置
                    this.resetForm('infoForm');
                    this.infoForm.nickName = this.user.profile.nickname;
                    this.infoForm.realName = this.user.profile.realName;
                    this.infoForm.introduction = this.user.profile.introduction;
                    this.infoForm.title = this.user.profile.title;
                    // this.infoForm.location = this.user.profile.location;
                    this.infoForm.location = provinceCity[this.user.profile.frequentLocation] || '未知';
                }
            } else if(type == 'inquire-mobile') {
                if(option == 'edit') {
                    this.isEditInquireMobile = true;
                } else {
                    this.isEditInquireMobile = false;
                    // 重置
                    this.resetForm('inquireForm');
                    this.inquireForm.inquirePhone = this.initInquirePhone;
                    this.inquireForm.inquireMobile = this.initInquireMobile;
                    // this.initNc();
                    // this.ncValidPassed = false;
                }
            } 
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        
        handleProvinceSelect() {
            this.infoForm.citySelect = '';
        },
        // 修改个人资料保存
        handleSaveClick(formName) {
            if(this.submitLoading) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    let params = {
                        nickName: this.infoForm.nickName,
                        realName: this.infoForm.realName,
                        introduction: this.infoForm.introduction,
                        title: this.infoForm.title,
                        // location: this.infoForm.citySelect,
                        location: 1,
                    }
                    _request({
                        url: personalCenterUrl.update_user_profile,
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        data: params
                    }).then(res => {
                        shortTips("个人资料修改成功");

                        this.isEditInfo = false;

                        let cityList = [];
                        let provinceText = provinceList.find(province => {
                            if(province.value == this.infoForm.provinceSelect) {
                                cityList = province.children;
                                return province;
                            }
                        }).label;
                        let cityText = cityList.find(city => {
                            return city.value == this.infoForm.citySelect
                        }).label;
                        this.infoForm.location = provinceText + " " + cityText;
                        this.$emit("change-profile", this.infoForm);
                    }).catch( err => {
                        console.log(err);
                    }).finally(() => {
                        this.submitLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 添加/修改寻访专用手机号/电话
        handleSaveInquireMobile(formName) {
            if(this.submitLoading) {
                return false;
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submitLoading = true;
                    let params = {
                        isNullDelete: true,
                        mobileNumber: this.inquireForm.inquirePhone,
                        verifyCode: this.inquireForm.Code,
                        telephoneNumber: this.inquireForm.inquireMobile,
                    }
                    phoneService.setPhone(params).then(res => {
                        shortTips("设置寻访专用手机/电话成功");

                        this.isEditInquireMobile = false;
                        
                        this.initInquirePhone = params.mobileNumber;
                        this.initInquireMobile = params.telephoneNumber;
                        this.getPhoneList();

                        // 加华为白名单
                        phoneService.addMobileWhiteList(this.inquireForm.inquirePhone);
                    }).catch( err => {
                        console.log(err);
                    }).finally(() => {
                        this.submitLoading = false;
                    });
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

    },
};
</script>

<style lang="scss" scoped>
.personal-info {
    .personal-info-form {
        padding-top: 15px;
        width: 622px;
        .el-form-item {
            &.form-item-left {
                margin-right: 10px;
            }
            &.form-item-right {
                margin-left: 10px;
            }
        }
        .personal-info-btn {
            text-align: right;
        }
        // .ncContainer-form-item {
        //     margin: 22px 10px 22px 0;
        // }

        .code-form-item {
            margin-top: 22px;
            .message-code {
                display: flex;
                .get-code{
                    margin-right: 12px;
                    width: 362px;
                    .get-code-btn{
                        // background: #CCCCCC;
                        background: #fff;
                        // color: white;
                        color: #ccc;
                        cursor: pointer;
                        width: 130px;
                        height: 38px;
                        box-sizing: border-box;
                        cursor: not-allowed;
                        border: none;
                        text-align: right;
                        padding-right: 10px;
                    }
                    .green{
                        // background: $primary;
                        color: $primary;
                        cursor: pointer;
                    }
                }
            }
        }

        .my-label {
            line-height: 20px;
            margin-bottom: 6px;
        }
    }
}
</style>