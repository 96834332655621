var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "personal-center" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "personal-center-contain",
        },
        [
          _c("div", { staticClass: "user-info" }, [
            _c("div", { staticClass: "header-left" }, [
              _c(
                "div",
                { staticClass: "user-info-left" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "user-info-avatar",
                      on: { click: _vm.handleUploadAvatar },
                    },
                    [
                      _vm.$store.state.user.userInfo.avatarUrl
                        ? _c("img", {
                            attrs: {
                              src:
                                _vm.avatarBase64 ||
                                _vm.$store.state.user.userInfo.avatarUrl +
                                  `?${Math.random()}`,
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: require("@src/assets/images/default-user.png"),
                              alt: "",
                            },
                          }),
                    ]
                  ),
                  _c("font-icon", {
                    staticClass: "avatar-edit",
                    attrs: { href: "#icon-bianji" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "user-info-right" }, [
                _c("span", { staticClass: "user-info-realname" }, [
                  _vm._v(_vm._s(_vm.user.profile.realName)),
                ]),
                _c("span", { staticClass: "user-info-nickname" }, [
                  _vm._v("@" + _vm._s(_vm.user.profile.nickname)),
                ]),
                _c(
                  "div",
                  { staticClass: "user-info-line" },
                  [
                    _c("span", { staticClass: "user-info-title" }, [
                      _vm._v(_vm._s(_vm.user.profile.title)),
                    ]),
                    _c("span", { staticClass: "halving-line" }, [_vm._v("|")]),
                    _c("span", { staticClass: "user-info-firmname" }, [
                      _vm._v(_vm._s(_vm.user.profile.company)),
                    ]),
                    _vm.user.profile.location
                      ? [
                          _c("span", { staticClass: "halving-line" }, [
                            _vm._v("|"),
                          ]),
                          _c(
                            "span",
                            { staticClass: "user-info-location" },
                            [
                              _c("font-icon", {
                                attrs: { href: "#icon-map-marker-o" },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(_vm.user.profile.location)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
                _c("div", { staticClass: "user-info-slogan" }, [
                  !_vm.isEditSlogan
                    ? _c("div", { staticClass: "user-info-slogan-text" }, [
                        _c("span", { staticClass: "slogan-text-content" }, [
                          _vm._v(
                            _vm._s(
                              _vm.user.profile.slogan
                                ? _vm.user.profile.slogan
                                : "这家伙很懒～什么也没留下"
                            )
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "flex",
                              "align-items": "center",
                            },
                            on: {
                              click: () => {
                                _vm.isEditSlogan = true
                              },
                            },
                          },
                          [
                            _c("font-icon", {
                              staticClass: "slogan-edit",
                              attrs: { href: "#icon-bianji" },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", { staticClass: "user-info-slogan-form" }, [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.sloganInput,
                              expression: "sloganInput",
                            },
                          ],
                          staticClass: "user-info-slogan-input",
                          attrs: { cols: "20", rows: "4", maxlength: "50" },
                          domProps: { value: _vm.sloganInput },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.sloganInput = $event.target.value
                            },
                          },
                        }),
                        _c(
                          "button",
                          {
                            staticClass: "user-info-slogan-btn",
                            on: { click: _vm.handleEditSloganDone },
                          },
                          [_vm._v("保存")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "user-info-slogan-btn user-info-slogan-btn-cancel",
                            on: {
                              click: () => {
                                _vm.isEditSlogan = false
                                _vm.sloganInput = _vm.user.profile.slogan
                              },
                            },
                          },
                          [_vm._v("取消")]
                        ),
                      ]),
                ]),
                _c("div", { staticClass: "perference-tags" }, [
                  _vm.user.jobTags && _vm.user.jobTags.length > 0
                    ? _c(
                        "div",
                        { staticClass: "perference-job-tabs" },
                        [
                          _vm._v("\n                            职位偏好："),
                          _vm._l(_vm.user.jobTags, function (jobtag) {
                            return _c(
                              "span",
                              {
                                key: jobtag,
                                staticClass: "perference-tab-item",
                              },
                              [_vm._v(_vm._s(jobtag))]
                            )
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.user.candidateTags && _vm.user.candidateTags.length > 0
                    ? _c(
                        "div",
                        { staticClass: "perference-candidate-tabs" },
                        [
                          _vm._v("\n                            人选偏好："),
                          _vm._l(
                            _vm.user.candidateTags,
                            function (candidatetag) {
                              return _c(
                                "span",
                                {
                                  key: candidatetag,
                                  staticClass: "perference-tab-item",
                                },
                                [_vm._v(_vm._s(candidatetag))]
                              )
                            }
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "header-right" }, [
              _c("ul", [
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "bg-circle" },
                    [_c("font-icon", { attrs: { href: "#icon-email" } })],
                    1
                  ),
                  _c("p", { attrs: { title: _vm.user.profile.email } }, [
                    _vm._v(_vm._s(_vm.user.profile.email)),
                  ]),
                ]),
                _c("li", [
                  _c(
                    "div",
                    { staticClass: "bg-circle" },
                    [
                      _c("font-icon", {
                        attrs: { href: "#icon-telephone-out" },
                      }),
                    ],
                    1
                  ),
                  _c("p", { attrs: { title: _vm.user.profile.mobile } }, [
                    _vm._v(_vm._s(_vm.user.profile.mobile)),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "tab-panel" }, [
            _c("div", { staticClass: "tab-panel-head" }, [
              _c(
                "span",
                {
                  staticClass: "tab-panel-head-item",
                  class: _vm.tabIndex == 0 ? "head-item-activity" : "",
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(0)
                    },
                  },
                },
                [_vm._v("我的优势")]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-panel-head-item",
                  class: _vm.tabIndex == 1 ? "head-item-activity" : "",
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(1)
                    },
                  },
                },
                [_vm._v("个人资料")]
              ),
              _c(
                "span",
                {
                  staticClass: "tab-panel-head-item",
                  class: _vm.tabIndex == 2 ? "head-item-activity" : "",
                  on: {
                    click: function ($event) {
                      return _vm.clickTab(2)
                    },
                  },
                },
                [_vm._v("账号设置")]
              ),
            ]),
            _c("div", { staticClass: "tab-panel-body" }, [
              _c("ul", { staticClass: "tab-panel-list" }, [
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabIndex == 0,
                        expression: "tabIndex == 0",
                      },
                    ],
                    staticClass: "tab-panel-list-item",
                  },
                  [
                    _c("div", { staticClass: "about-me" }, [
                      !_vm.isHideItemNull &&
                      (!_vm.user.userProfileTags.introduction ||
                        _vm.user.userProfileTags.myIndustryTags.length == 0 ||
                        _vm.user.userProfileTags.myJobTags.length == 0 ||
                        _vm.user.userProfileTags.myCustomerTags.length == 0)
                        ? _c("div", { staticClass: "about-me-item-null" }, [
                            _vm._v(
                              "\n                                完善您的从业信息，更多的HR可以定向邀请您"
                            ),
                            _c("i", {
                              staticClass: "icon-close",
                              on: { click: _vm.closeItemNull },
                            }),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "about-me-item" }, [
                        _c("div", { staticClass: "about-me-item-header" }, [
                          _vm._v("我擅长的行业领域"),
                          _c(
                            "span",
                            {
                              staticClass: "user-introduction-change",
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditBtn("industryAddTag")
                                },
                              },
                            },
                            [
                              _c("font-icon", { attrs: { href: "#icon-add" } }),
                              _vm._v("添加"),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "about-me-item-body" },
                          [
                            _vm.user.userProfileTags.myIndustryTags.length > 0
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "over2line",
                                        rawName: "v-over2line",
                                      },
                                    ],
                                    staticClass: "user-industry-field-list",
                                  },
                                  _vm._l(
                                    _vm.user.userProfileTags.myIndustryTags,
                                    function (indestryFieldItem, index) {
                                      return _c("personalTag", {
                                        key: indestryFieldItem.id,
                                        staticClass: "personal-tag-item",
                                        attrs: {
                                          tag: indestryFieldItem,
                                          index: index,
                                          type: 0,
                                        },
                                        on: { "delete-tag": _vm.deleteTag },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("div", { staticClass: "empty" }, [
                                  _vm._v("请添加擅长的领域"),
                                ]),
                            _c("addPersonalTag", {
                              ref: "industryAddTag",
                              staticClass: "personal-tag-add",
                              attrs: {
                                isNone:
                                  _vm.user.userProfileTags.myIndustryTags
                                    .length > 0
                                    ? false
                                    : true,
                                industryField: _vm.industryField,
                                type: 0,
                              },
                              on: { "add-tag": _vm.addTag },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "about-me-item" }, [
                        _c("div", { staticClass: "about-me-item-header" }, [
                          _vm._v("我擅长的职位"),
                          _c(
                            "span",
                            {
                              staticClass: "user-introduction-change",
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditBtn("skilledJobAddTag")
                                },
                              },
                            },
                            [
                              _c("font-icon", { attrs: { href: "#icon-add" } }),
                              _vm._v("添加"),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "about-me-item-body" },
                          [
                            _vm.user.userProfileTags.myJobTags.length > 0
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "over2line",
                                        rawName: "v-over2line",
                                      },
                                    ],
                                    staticClass: "user-skilled-job-list",
                                  },
                                  _vm._l(
                                    _vm.user.userProfileTags.myJobTags,
                                    function (skilledJobItem, index) {
                                      return _c("personalTag", {
                                        key: skilledJobItem.id,
                                        staticClass: "personal-tag-item",
                                        attrs: {
                                          tag: skilledJobItem,
                                          type: 1,
                                          index: index,
                                        },
                                        on: { "delete-tag": _vm.deleteTag },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("div", { staticClass: "empty" }, [
                                  _vm._v("请添加擅长的职位"),
                                ]),
                            _c("addPersonalTag", {
                              ref: "skilledJobAddTag",
                              staticClass: "personal-tag-add",
                              attrs: {
                                isNone:
                                  _vm.user.userProfileTags.myJobTags.length > 0
                                    ? false
                                    : true,
                                jobTypes: _vm.jobTypes,
                                type: 1,
                              },
                              on: { "add-tag": _vm.addTag },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c("div", { staticClass: "about-me-item" }, [
                        _c("div", { staticClass: "about-me-item-header" }, [
                          _vm._v("我有哪些公司资源"),
                          _c(
                            "span",
                            {
                              staticClass: "user-introduction-change",
                              on: {
                                click: function ($event) {
                                  return _vm.clickEditBtn("firmResourceAddTag")
                                },
                              },
                            },
                            [
                              _c("font-icon", { attrs: { href: "#icon-add" } }),
                              _vm._v("添加"),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "about-me-item-body" },
                          [
                            _vm.user.userProfileTags.myCustomerTags.length > 0
                              ? _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "over2line",
                                        rawName: "v-over2line",
                                      },
                                    ],
                                    staticClass: "user-firm-resource-list",
                                  },
                                  _vm._l(
                                    _vm.user.userProfileTags.myCustomerTags,
                                    function (firmResourceItem, index) {
                                      return _c("personalTag", {
                                        key: firmResourceItem.id,
                                        staticClass: "personal-tag-item",
                                        attrs: {
                                          tag: firmResourceItem,
                                          type: 2,
                                          index: index,
                                        },
                                        on: { "delete-tag": _vm.deleteTag },
                                      })
                                    }
                                  ),
                                  1
                                )
                              : _c("div", { staticClass: "empty" }, [
                                  _vm._v("请添加我的公司资源"),
                                ]),
                            _c("addPersonalTag", {
                              ref: "firmResourceAddTag",
                              staticClass: "personal-tag-add",
                              attrs: {
                                isNone:
                                  _vm.user.userProfileTags.myCustomerTags
                                    .length > 0
                                    ? false
                                    : true,
                                type: 2,
                              },
                              on: { "add-tag": _vm.addTag },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabIndex == 1,
                        expression: "tabIndex == 1",
                      },
                    ],
                    staticClass: "tab-panel-list-item",
                  },
                  [
                    _c("personal-info", {
                      ref: "personalInfoRef",
                      attrs: { user: _vm.user },
                      on: { "change-profile": _vm.changeProfile },
                    }),
                  ],
                  1
                ),
                _c(
                  "li",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.tabIndex == 2,
                        expression: "tabIndex == 2",
                      },
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.weChatBindLoading,
                        expression: "weChatBindLoading",
                      },
                    ],
                    staticClass: "tab-panel-list-item",
                  },
                  [
                    _c("h2", { staticClass: "bind-title" }, [
                      _vm._v("账号信息"),
                    ]),
                    _c(
                      "div",
                      { staticClass: "change-phone" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "ruleForm",
                            staticClass: "account-ruleForm",
                            attrs: { model: _vm.ruleForm, rules: _vm.rules },
                          },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { prop: "Mobile" } },
                              [
                                _c("div", { staticClass: "my-label" }, [
                                  _vm._v("手机号"),
                                ]),
                                _c("el-input", {
                                  staticClass: "account-input",
                                  attrs: {
                                    type: "text",
                                    disabled: !_vm.isEditMobile,
                                    placeholder: "输入新手机号",
                                  },
                                  model: {
                                    value: _vm.ruleForm.Mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.ruleForm, "Mobile", $$v)
                                    },
                                    expression: "ruleForm.Mobile",
                                  },
                                }),
                                _c(
                                  "el-button",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: !_vm.isEditMobile,
                                        expression: "!isEditMobile",
                                      },
                                    ],
                                    staticClass: "option-btn",
                                    attrs: { loading: _vm.submitLoading },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEditClick(
                                          "mobile",
                                          "edit"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditMobile,
                                    expression: "isEditMobile",
                                  },
                                ],
                              },
                              [
                                _c("div", { staticClass: "col-sm-12" }, [
                                  _c("div", {
                                    staticClass: "nc-container",
                                    attrs: { id: "ncContainer1" },
                                  }),
                                ]),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditMobile,
                                    expression: "isEditMobile",
                                  },
                                ],
                                staticClass: "code-form-item",
                                attrs: { prop: "Code" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "message-code" },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        staticClass: "get-code",
                                        attrs: { placeholder: "输入验证码" },
                                        model: {
                                          value: _vm.ruleForm.Code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.ruleForm, "Code", $$v)
                                          },
                                          expression: "ruleForm.Code",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            staticClass: "get-code-btn",
                                            class: {
                                              green:
                                                _vm.ncValidPassed &&
                                                _vm.canGetCode,
                                            },
                                            attrs: {
                                              slot: "suffix",
                                              disabled:
                                                !_vm.canGetCode || !!_vm.timer,
                                              type: "button",
                                              loading: _vm.codeLoading,
                                            },
                                            on: { click: _vm.getCode },
                                            slot: "suffix",
                                          },
                                          [
                                            !_vm.codeGetted
                                              ? [_vm._v("获取验证码")]
                                              : [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.timer
                                                        ? `${_vm.countDown}秒后`
                                                        : ""
                                                    ) + "重新获取"
                                                  ),
                                                ],
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "help-not-receive" },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "not-received-message-code",
                                            on: {
                                              click: function ($event) {
                                                _vm.helpVisible = true
                                              },
                                            },
                                          },
                                          [_vm._v("没有收到短信验证码？")]
                                        ),
                                        _vm.helpVisible
                                          ? _c(
                                              "el-card",
                                              { staticClass: "box-card" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "closeHelp",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.helpVisible = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("×")]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text item" },
                                                  [
                                                    _vm._v(
                                                      "· 网络通讯异常可能会造成短信丢失，请重新发送短信"
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text item" },
                                                  [
                                                    _vm._v(
                                                      "· 请核实手机是否欠费停机，或者屏蔽了系统短信"
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditMobile,
                                    expression: "isEditMobile",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "option-btn",
                                    attrs: { loading: _vm.submitLoading },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEditClick(
                                          "mobile",
                                          "cancel"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "option-btn",
                                    attrs: {
                                      type: "primary",
                                      loading: _vm.submitLoading,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChangePhone("ruleForm")
                                      },
                                    },
                                  },
                                  [_vm._v("保存")]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                staticClass: "email-form-item",
                                attrs: { prop: "Email" },
                              },
                              [
                                _c("div", { staticClass: "my-label" }, [
                                  _vm._v("邮箱"),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "email" },
                                  [
                                    _c("el-input", {
                                      staticClass: "account-input",
                                      attrs: {
                                        type: "text",
                                        placeholder: "输入新邮箱",
                                        disabled: !_vm.isEditEmail,
                                      },
                                      model: {
                                        value: _vm.ruleForm.Email,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.ruleForm, "Email", $$v)
                                        },
                                        expression: "ruleForm.Email",
                                      },
                                    }),
                                    !_vm.user.isEmployer &&
                                    !_vm.user.profile.emailConfirmed
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isEditEmail,
                                                    expression: "!isEditEmail",
                                                  },
                                                ],
                                                staticClass:
                                                  "email-verify-text",
                                              },
                                              [_vm._v("未验证")]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isEditEmail,
                                                    expression: "isEditEmail",
                                                  },
                                                ],
                                                staticClass: "help-not-receive",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "not-received-message-code",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.helpEmailVisible = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("收不到邮箱？")]
                                                ),
                                                _vm.helpEmailVisible
                                                  ? _c(
                                                      "el-card",
                                                      {
                                                        staticClass: "box-card",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "closeHelp",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.helpEmailVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("×")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "· 检查下邮箱是否填写准确"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "· 查看是否发送至垃圾邮件中"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isEditEmail,
                                                    expression: "!isEditEmail",
                                                  },
                                                ],
                                                staticClass: "option-btn",
                                                attrs: {
                                                  loading: _vm.submitLoading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEditClick(
                                                      "email",
                                                      "edit"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("去验证")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    !_vm.user.isEmployer &&
                                    _vm.user.profile.emailConfirmed
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "span",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isEditEmail,
                                                    expression: "!isEditEmail",
                                                  },
                                                ],
                                                staticClass:
                                                  "email-verify-text verified",
                                              },
                                              [
                                                _c("font-icon", {
                                                  staticClass: "icon-verify",
                                                  attrs: {
                                                    href: "#icon-yanzhengma",
                                                  },
                                                }),
                                                _vm._v(
                                                  "\n                                                已验证\n                                            "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: _vm.isEditEmail,
                                                    expression: "isEditEmail",
                                                  },
                                                ],
                                                staticClass: "help-not-receive",
                                              },
                                              [
                                                _c(
                                                  "p",
                                                  {
                                                    staticClass:
                                                      "not-received-message-code",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.helpEmailVisible = true
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("收不到邮箱？")]
                                                ),
                                                _vm.helpEmailVisible
                                                  ? _c(
                                                      "el-card",
                                                      {
                                                        staticClass: "box-card",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "closeHelp",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.helpEmailVisible = false
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("×")]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "· 检查下邮箱是否填写准确"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "text item",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "· 查看是否发送至垃圾邮件中"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "show",
                                                    rawName: "v-show",
                                                    value: !_vm.isEditEmail,
                                                    expression: "!isEditEmail",
                                                  },
                                                ],
                                                staticClass: "option-btn",
                                                attrs: {
                                                  loading: _vm.submitLoading,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.handleEditClick(
                                                      "email",
                                                      "edit"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("更换")]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-form-item",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.isEditEmail,
                                    expression: "isEditEmail",
                                  },
                                ],
                              },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "option-btn",
                                    attrs: { loading: _vm.sendLoading },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleEditClick(
                                          "email",
                                          "cancel"
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("取消")]
                                ),
                                !_vm.isSendVerifyEmail
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "option-btn",
                                        attrs: {
                                          type: "primary",
                                          loading: _vm.sendLoading,
                                        },
                                        on: { click: _vm.handleEmailVerify },
                                      },
                                      [_vm._v("发送验证邮件")]
                                    )
                                  : _c(
                                      "button",
                                      { staticClass: "email-send-btn" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.remainSecondSend) +
                                            "s 后可重新发送"
                                        ),
                                      ]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c("h2", { staticClass: "bind-title" }, [
                      _vm._v("企业微信授权"),
                    ]),
                    _vm.isAuthorize && !_vm.isBind
                      ? _c(
                          "div",
                          { staticClass: "bind-option" },
                          [
                            _c("font-icon", {
                              staticClass: "bind-icon",
                              attrs: { href: "#icon-qiyeweixin" },
                            }),
                            _c(
                              "el-button",
                              { on: { click: _vm.bindEnterpriseWeChat } },
                              [_vm._v("绑定")]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "bind-option" },
                          [
                            _c("font-icon", {
                              staticClass: "bind-icon",
                              attrs: { href: "#icon-bianzu" },
                            }),
                            !_vm.isAuthorize
                              ? _c("span", [
                                  _vm._v(
                                    "猎必得未授权自建应用代开发或应用未上线"
                                  ),
                                ])
                              : _c("span", [_vm._v("已绑定")]),
                          ],
                          1
                        ),
                  ]
                ),
              ]),
            ]),
          ]),
        ]
      ),
      _c("upload-avatar", { ref: "uploadAvatar" }),
      _c("MobileVerifyDialog", {
        ref: "MobileVerifyDialogRef",
        on: { "bind-success": _vm.bindSuccessRefresh },
      }),
      _c("ScanErrorDialog", { ref: "scanErrorDialogRef" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }