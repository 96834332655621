var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "scan-error-dialog",
      attrs: {
        title: "提示",
        visible: _vm.dialogVisible,
        width: "480px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.handleClose,
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("h1", [
          _c("img", {
            attrs: { src: require("@src/assets/images/error.png"), alt: "" },
          }),
          _vm._v("扫码失败"),
        ]),
        _c("div", { staticClass: "text" }, [
          _c("p", [_vm._v("可能的失败原因：")]),
          _c("p", [_vm._v("猎必得未授权自建应用代开发或应用未上线")]),
          _c("p", [_vm._v("当前账号猎企公司与企业微信公司不一致")]),
        ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }