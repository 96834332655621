var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "add-personal-tag" }, [
    _vm.isAddPersonalTab && _vm.type == 0
      ? _c("div", { staticClass: "add-personal-tag-handle" }, [
          _c("i", {
            staticClass: "icon-close",
            on: { click: _vm.closeHandleArea },
          }),
          _c(
            "div",
            { staticClass: "handle-choose" },
            [
              _vm.industryField.length > 0
                ? _vm._l(
                    _vm.industryField,
                    function (industryFieldItem, index) {
                      return _c(
                        "span",
                        {
                          key: index,
                          staticClass: "industry-field-text",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("add-tag", {
                                text: industryFieldItem,
                                type: _vm.type,
                              })
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(industryFieldItem) +
                              "\n                "
                          ),
                        ]
                      )
                    }
                  )
                : _c("div", { staticClass: "data-null" }, [
                    _vm._v("\n                暂无数据\n            "),
                  ]),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.isAddPersonalTab && _vm.type == 1
      ? _c("div", { staticClass: "add-personal-tag-handle" }, [
          _c("i", {
            staticClass: "icon-close",
            on: { click: _vm.closeHandleArea },
          }),
          _c(
            "div",
            { staticClass: "handle-choose" },
            [
              _vm.jobTypes.length > 1
                ? _vm._l(_vm.jobTypes, function (highLevel) {
                    return _c(
                      "div",
                      {
                        key: highLevel.id,
                        staticClass: "job-list job-level-high-list",
                      },
                      [
                        _c(
                          "h3",
                          { staticClass: "job-item-header job-level-high" },
                          [
                            _c("span", [_vm._v(_vm._s(highLevel.name))]),
                            _c("span", { staticClass: "line" }),
                          ]
                        ),
                        _vm._l(highLevel.subJobCategories, function (jobType) {
                          return _c(
                            "div",
                            { key: jobType.id, staticClass: "job-list" },
                            [
                              _c("h3", { staticClass: "job-item-header" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(jobType.name) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._l(
                                jobType.subJobCategories,
                                function (subJobCategory) {
                                  return _c(
                                    "span",
                                    {
                                      key: subJobCategory.id,
                                      staticClass: "job-item-text",
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("add-tag", {
                                            text: subJobCategory.name,
                                            type: _vm.type,
                                            id: subJobCategory.id,
                                          })
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(subJobCategory.name) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        }),
                      ],
                      2
                    )
                  })
                : _c("div", { staticClass: "data-null" }, [
                    _vm._v("\n                暂无数据\n            "),
                  ]),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm.isAddPersonalTab && _vm.type == 2
      ? _c("div", { staticClass: "add-personal-tag-handle" }, [
          _c("i", {
            staticClass: "icon-close",
            on: { click: _vm.closeHandleArea },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.addFirmResourceText,
                expression: "addFirmResourceText",
              },
            ],
            staticClass: "handle-input",
            attrs: { type: "text", placeholder: "输入公司名称" },
            domProps: { value: _vm.addFirmResourceText },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.addFirmResourceText = $event.target.value
              },
            },
          }),
          _c(
            "button",
            {
              staticClass: "handle-btn",
              on: {
                click: function ($event) {
                  return _vm.$emit("add-tag", {
                    text: _vm.addFirmResourceText,
                    type: _vm.type,
                  })
                },
              },
            },
            [_vm._v("\n            新增\n        ")]
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }