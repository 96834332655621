<template>
    <el-dialog
        title="提示"
        :visible="dialogVisible"
        width="480px"
        class="scan-error-dialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :before-close="handleClose">
        <div class="content">
            <h1><img src="~@src/assets/images/error.png" alt="">扫码失败</h1>
            <div class="text">
                <p>可能的失败原因：</p>
                <p>猎必得未授权自建应用代开发或应用未上线</p>
                <p>当前账号猎企公司与企业微信公司不一致</p>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false,
        };
    },

    mounted() {

    },

    methods: {
        show() {
            this.dialogVisible = true;
        },
        handleClose() {
            this.dialogVisible = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.scan-error-dialog {
    /deep/.el-dialog {
        border-radius: 12px;
        .el-dialog__header {
            border-bottom: none;
            font-weight: 600;
        }
    }
    .el-dialog__body {
        padding: 0 24px 20px;
        .content {
            h1 {
                color: #333;
                font-size: 18px;
                font-weight: 600;
                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 4px;
                    vertical-align: top;
                }
            }
            .text {
                font-size: 12px;
                color: #999;
                p {
                    margin: 0;
                    line-height: 18px;
                }
            }
        }
    }
    .dialog-footer {
        .el-button {
            min-width: 90px;
            border-radius: 5px;
        }
    }
}
</style>